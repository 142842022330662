import { Chart, getElementAtEvent } from "react-chartjs-2";
import { Chart as ChartJS, registerables, TooltipItem } from "chart.js";
import {
  WordCloudChart,
  WordCloudController,
  WordElement,
} from "chartjs-chart-wordcloud";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getFirst3Words } from "../../utils/getFirst3Words";
import { useRef } from "react";
import { MetadataStatistic } from "../../api/DatasetApi";

ChartJS.register(
  ...registerables,
  WordCloudChart,
  WordCloudController,
  WordElement,
  ChartDataLabels
);

export function Wordcloud({
  labels,
  values,
  allData,
  setSentencesData,
}: {
  labels: string[];
  values: number[];
  allData: MetadataStatistic[];
  setSentencesData: (data: MetadataStatistic[]) => void;
}) {
  const words = labels.map((label, index) => {
    return { key: label, value: values[index] };
  });

  const maxVal = Math.max(...words.map((val) => val.value));
  const minVal = Math.min(...words.map((val) => val.value));
  const valRange = maxVal - minVal;

  const maxSize = 50;
  const minSize = 20;
  const sizeRange = maxSize - minSize;

  const data = {
    labels: words.map((d) => getFirst3Words(d.key)),
    datasets: [
      {
        label: "Term cloud",
        data: words.map(
          (d) =>
            ((d.value - minVal) * sizeRange) / valRange + minSize || minSize
        ),
        datalabels: {
          display: false,
        },
        minRotation: 0,
        padding: 5,
        fit: false,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        enabled: true,
        callbacks: {
          label: function (context: TooltipItem<"wordCloud">) {
            return words[context.dataIndex].key;
          },
          title: function () {
            return "";
          },
        },
      },
    },
  };

  const chartRef = useRef();
  const onClick = (event: any) => {
    if (!chartRef.current) {
      return;
    }

    const elements = getElementAtEvent(chartRef.current, event);

    if (elements.length === 0) {
      return;
    }

    const labelIndex = elements[0].index;

    const filteredData = allData.filter((d) =>
      d.sentence.toLowerCase().includes(words[labelIndex].key)
    );

    setSentencesData(filteredData);
  };

  return (
    <Chart
      ref={chartRef}
      type={"wordCloud"}
      data={data}
      onClick={onClick}
      plugins={[WordCloudChart, WordElement, WordCloudController]}
      options={options}
    />
  );
}
