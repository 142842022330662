import Papa from "papaparse";
import { useEffect, useState } from "react";
import "./ColumnLabeler.css";
import TooltipIcon from "../../assets/question-mark.svg";
import { Tooltip } from "react-tooltip";

export function ColumnLabeler({
  csv,
  reviewColumn,
  setreviewColumn,
  headerRow,
  setHeaderRow,
  delimiter,
  isLangPreview,
  isDatePreview,
  setCsvData,
}: {
  csv: File;
  reviewColumn: number | undefined;
  setreviewColumn(val: number | undefined): void;
  headerRow: boolean;
  setHeaderRow(state: boolean): void;
  delimiter: string;
  isLangPreview: boolean;
  isDatePreview: boolean;
  setCsvData: (v: string[][]) => void;
}) {
  const [csvValues, setCsvValues] = useState<string[][]>([[]]);
  const [hoveredCol, setHoveredCol] = useState<number | null>(null);

  useEffect(() => {
    let config: any = {
      complete: (result: Papa.ParseResult<string[]>) => {
        setCsvValues(result.data);
        setCsvData(result.data);
      },
    };

    if (delimiter != "") {
      config = {
        ...config,
        delimiter: delimiter,
      };
    } else {
      config = {
        ...config,
        delimitersToGuess: [
          ";",
          ",",
          "\t",
          "|",
          Papa.RECORD_SEP,
          Papa.UNIT_SEP,
        ],
      };
    }

    Papa.parse(csv, config);
  }, [csv, delimiter]);

  return (
    <>
      <div
        className={
          "flex flex-wrap justify-space-between align-items-baseline mb-2"
        }
      >
        <div className={"csv-table-title"}>
          Select the{" "}
          {isLangPreview ? "language" : isDatePreview ? "date" : "review"}{" "}
          column
          <img
            className={"ml-2"}
            data-tooltip-html={
              "Preview of the selected file. Please select the column that contains the text data. Use the ‘Contains header row’ checkbox to indicate whether the first row should be excluded from the analysis (checked) or already contains data (unchecked)."
            }
            data-tooltip-id="csv-table-tooltip"
            data-tooltip-place="top"
            src={TooltipIcon}
            alt={"Csv data tooltip icon"}
          />
          <Tooltip id="csv-table-tooltip" className={"tooltip"} />
        </div>
        <label>
          <input
            className={"header-check"}
            type={"checkbox"}
            onChange={() => setHeaderRow(!headerRow)}
            defaultChecked={true}
          />
          Contains header row
        </label>
      </div>

      <div className={"center-container"}>
        <div className={"scroll-wrapper"}>
          <table className={"csv-table mb-2"}>
            {headerRow ? (
              <thead>
                <tr>
                  {csvValues[0].map((header, i) => (
                    <th
                      onClick={() => setreviewColumn(i)}
                      className={`${hoveredCol === i && "highlighted-col"} ${
                        reviewColumn === i && "selected-col"
                      }`}
                      onMouseOver={() => setHoveredCol(i)}
                      onMouseOut={() => setHoveredCol(null)}
                      key={`header-${i}`}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
            ) : null}
            <tbody>
              {csvValues
                .slice(!headerRow ? 0 : 1, !headerRow ? 5 : 6)
                .map((row, i) => (
                  <tr key={`row-${i}`}>
                    {row.map((value, j) => {
                      return (
                        <td
                          className={`${
                            hoveredCol === j && "highlighted-col"
                          } ${reviewColumn === j && "selected-col"}`}
                          key={`row-${i}-col-${j}`}
                          onClick={() => setreviewColumn(j)}
                          onMouseOver={() => setHoveredCol(j)}
                          onMouseOut={() => setHoveredCol(null)}
                        >
                          {value}
                        </td>
                      );
                    })}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
