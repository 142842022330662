import { useState, useEffect, Fragment } from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { routes } from "../../constants/routes";
import AuthApi from "../../api/AuthApi";
import { FormWindow } from "../../components/form-window/FormWindow";
import { Input } from "../../components/input/Input";
import { Toast } from "../../components/toast/Toast";
import lock from "../../assets/lock.svg";
import "./ConfirmAccount.css";

export function ConfirmAccount() {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [passwordRepeatShow, setPasswordRepeatShow] = useState<boolean>(false);
  const [toast, setToast] = useState("");
  const [showInput, setShowInput] = useState(false);
  const navigate = useNavigate();

  const [URLSearchParams] = useSearchParams();
  const token = URLSearchParams.get("token");
  const email = URLSearchParams.get("email");

  useEffectOnce(() => {
    if (!token || !email) {
      setToast("Something went wrong!");
    } else {
      setShowInput(true);
    }
  });

  useEffect(() => {
    if (password !== repeatPassword && repeatPassword !== "") {
      setToast("Passwords are not matching...");
    } else {
      setToast("");
    }
  }, [password, repeatPassword]);

  async function onSubmitConfirm(
    email: string,
    password: string,
    repeatPassword: string,
    token: string
  ): Promise<void> {
    try {
      if (password === repeatPassword) {
        const user = await AuthApi.confirmPassword(email, password, token);
        navigate(generatePath(routes.dashboard, { companyId: user.companyId }));
      }
    } catch (error) {
      setToast("Something went wrong while login in!");
      setTimeout(() => setToast(""), 3000);
    }
  }

  return (
    <div className="login-upper-container flex">
      <div className="login-container flex center">
        <div className="login-input-container flex">
          <FormWindow
            form={
              <Fragment>
                {showInput && (
                  <div>
                    <div className={"sub-title"}>
                      <label>Choose a password</label>
                    </div>
                    <Fragment>
                      <Input
                        value={password}
                        onChange={setPassword}
                        placeholder="password"
                        required={true}
                        type={passwordShow ? "text" : "password"}
                        image={lock}
                        showPasswordIcon={true}
                        isRevealedPassword={passwordShow}
                        setIsRevealedPassword={setPasswordShow}
                        minLength={8}
                      />
                      <Input
                        value={repeatPassword}
                        onChange={setRepeatPassword}
                        placeholder="repeat password"
                        required={true}
                        type={passwordRepeatShow ? "text" : "password"}
                        image={lock}
                        showPasswordIcon={true}
                        isRevealedPassword={passwordRepeatShow}
                        setIsRevealedPassword={setPasswordRepeatShow}
                        minLength={8}
                      />
                      <button className="button mt-4" type="submit">
                        Confirm
                      </button>
                    </Fragment>
                  </div>
                )}
                {toast.length > 0 && (
                  <div className="confirmation-toast mt-4">
                    <Toast text={toast} fail={true} />
                  </div>
                )}
              </Fragment>
            }
            onSubmit={async () => {
              if (email && token)
                await onSubmitConfirm(email, password, repeatPassword, token);
            }}
          />
        </div>
      </div>
    </div>
  );
}
