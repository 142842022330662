import "./Sidebar.css";
import { useEffect, useState } from "react";
import Logout from "../../assets/logout.svg";
import UploadButton from "../../assets/upload-button.svg";
import UploadButtonLight from "../../assets/upload-button-light.svg";
import Remove from "../../assets/remove.svg";
import RemoveLight from "../../assets/remove-light.svg";
import Logo from "../../assets/alfasent-logo.png";
import Return from "../../assets/return.svg";
import Csv from "../../assets/csv.png";
import GoogleReviews from "../../assets/google-reviews.png";
import Trustpilot from "../../assets/trustpilot.png";
import authApi, { UserType } from "../../api/AuthApi";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useUserContext } from "../../hooks/useUserContext";
import Dialog from "../dialog/Dialog";
import { UploadCsv } from "../upload-csv/UploadCsv";
import DatasetApi from "../../api/DatasetApi";
import { DeleteForm } from "../delete-form/DeleteForm";
import { Toast } from "../toast/Toast";
import GoogleApi, {
  SavedGoogleAccountWithLocations,
} from "../../api/GoogleApi";
import { Select } from "../Select";
import { Input } from "../input/Input";

export function Sidebar() {
  const [datasets, setDatasets] = useState<
    {
      name: string;
      id: string;
      isMain: boolean;
      subsets: { name: string; id: string; uploadedAt: string }[];
      uploadedAt: string;
      mainDatasetName: string;
    }[]
  >([]);
  const [selectedDataset, setSelectedDataset] = useState<{
    name: string;
    id: string;
    isMain: boolean;
  }>();
  const [mainDatasetId, setMainDatasetId] = useState<string | undefined>();
  const [selectingDataSource, setSelectingDataSource] =
    useState<boolean>(false);
  const [uploadingCsv, setUploadingCsv] = useState<boolean>(false);
  const [uploadingGoogleReviews, setUploadingGoogleReviews] =
    useState<boolean>(false);
  const [uploadingTrustpilot, setUploadingTrustpilot] =
    useState<boolean>(false);
  const [savedGoogleAccountWithLocations, setSavedGoogleAccountWithLocations] =
    useState<SavedGoogleAccountWithLocations[]>([]);
  const [deleting, setDeleting] = useState<{ name: string; id: string } | null>(
    null
  );
  const [refreshingGoogle, setRefreshingGoogle] = useState(false);
  const [isGoogleLinked, setIsGoogleLinked] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [toast, setToast] = useState<string>("");
  const [uploading, setUploading] = useState(false);

  const [selectedGoogleAccount, setSelectedGoogleAccount] = useState<
    string | null
  >(null);
  const [selectedGoogleLocation, setSelectedGoogleLocation] = useState<
    string | null
  >(null);
  const [datasetName, setDatasetName] = useState("");
  const [error, setError] = useState("");

  const foundGoogleAccount = savedGoogleAccountWithLocations.find(
    (s) => s.id === selectedGoogleAccount
  );

  const { user } = useUserContext();
  const { companyId } = useParams();
  const navigate = useNavigate();

  const selectDataSourceTooltipText =
    "Select a data source for the analysis. This can be either a CSV file, Google Reviews or Trustpilot.";

  const uploadCsvTooltipText =
    "Upload a file in csv format. This file must contain a column with the text data (e.g. reviews) to be analyzed. You can select this column after choosing a file.";

  const googleUploadTooltipText =
    "Please select the correct account to start the synchronisation with your google account";

  const linkGoogleTooltipText =
    "To use Google Reviews as a data source, you first need to authorize your Google account.";

  useEffect(() => {
    if (companyId) {
      fetchDatasets(companyId);
    }
  }, [companyId]);

  function fetchDatasets(companyId: string) {
    DatasetApi.getDatasets(companyId).then((datasets) => {
      setDatasets(
        datasets
          .sort((a, b) => Date.parse(b.uploadedAt) - Date.parse(a.uploadedAt))
          .filter((dataset) => !dataset.mainDatasetMetadataId)
          .map((dataset) => {
            return {
              name: dataset.datasetName,
              mainDatasetName: dataset.mainDatasetName ?? dataset.datasetName,
              id: dataset.id,
              isMain: !dataset.mainDatasetMetadataId,
              uploadedAt: dataset.uploadedAt,
              subsets: datasets
                .filter((d) => d.mainDatasetMetadataId === dataset.id)
                .sort(
                  (a, b) => Date.parse(a.uploadedAt) - Date.parse(b.uploadedAt)
                )
                .map((d) => {
                  return {
                    name: d.datasetName,
                    id: d.id,
                    uploadedAt: d.uploadedAt,
                  };
                }),
            };
          })
      );
    });

    GoogleApi.isConnected().then((result) => {
      setIsGoogleLinked(result);
    });

    GoogleApi.getDetails().then((result) => {
      if (result.length > 0) {
        setSelectedGoogleAccount(result[0].id);

        if (result[0].googleLocations.length > 0) {
          setSelectedGoogleLocation(result[0].googleLocations[0].id);
        }
      }

      setSavedGoogleAccountWithLocations(result);
    });
  }

  function handleError() {
    setToast("Something went wrong");
    setTimeout(() => setToast(""), 1000 * 3);
  }

  function handleLogout() {
    authApi.logout();
    navigate(routes.login);
  }

  return (
    <div className={"sidebar-wrapper flex-column flex"}>
      <div className={"justify-space-evenly flex flex-wrap"}>
        {user?.userType === UserType.ADMIN ? (
          <div
            onClick={() => navigate(routes.admin)}
            className={"logo-wrapper flex animated action-button"}
          >
            <img src={Return} alt={"Return to overview"} />
          </div>
        ) : null}
        <div className={"logo-wrapper flex"}>
          <img className={"logo"} src={Logo} alt={"Alfasent logo"} />
        </div>
        {toast.length > 0 && <Toast text={toast} fail={true} />}
      </div>
      <div className="sidebar-wrapper-inner">
        <div className={"entries flex flex-column flex-1"}>
          {datasets.map((dataset) => {
            return (
              <div key={dataset.id}>
                <div
                  className={
                    "entry animated pointer" +
                    (`${dataset.name}-all` === selectedDataset?.name
                      ? " selected-dataset"
                      : "")
                  }
                  onClick={() => {
                    setSelectedDataset({
                      ...dataset,
                      name: `${dataset.name}-all`,
                    });
                    navigate(
                      generatePath(routes.dashboard, {
                        companyId: companyId,
                      }) + `?dataset=${dataset.id}-all`
                    );
                  }}
                >
                  <div className="flex items-center">
                    {showDelete ? (
                      <div className={"sidebar-remove-icon"}>
                        <img
                          src={RemoveLight}
                          alt={"Remove dataset"}
                          onClick={() => setDeleting(dataset)}
                        />
                      </div>
                    ) : null}
                    <div>{dataset.mainDatasetName}</div>
                    {dataset.isMain ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setMainDatasetId(dataset.id);
                          setSelectingDataSource(true);
                        }}
                        className={"subset-button"}
                      >
                        <img
                          className="upload-icon"
                          src={UploadButtonLight}
                          alt={"upload button"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="ml-4">
                  {[dataset as { name: string; id: string; uploadedAt: string }]
                    .concat(dataset.subsets)
                    .map((item, index) => {
                      return (
                        <div
                          key={dataset.id + "-child" + index}
                          className={
                            "entry animated pointer" +
                            (item.name === selectedDataset?.name
                              ? " selected-dataset"
                              : "")
                          }
                          onClick={() => {
                            setSelectedDataset({ ...item, isMain: false });
                            navigate(
                              generatePath(routes.dashboard, {
                                companyId: companyId,
                              }) + `?dataset=${item.id}`
                            );
                          }}
                        >
                          <div className="flex items-center">
                            {showDelete ? (
                              <div className={"sidebar-remove-icon"}>
                                <img
                                  src={RemoveLight}
                                  alt={"Remove dataset"}
                                  onClick={() => setDeleting(item)}
                                />
                              </div>
                            ) : null}
                            <div className="sidebar-item">{item.name}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
          {user?.companyId === companyId ? (
            <div
              onClick={() => {
                setMainDatasetId(undefined);
                setSelectingDataSource(true);
              }}
              className={"upload-button animated action-button pill"}
            >
              <img
                className="upload-icon"
                src={UploadButton}
                alt={"upload button"}
              />
            </div>
          ) : null}
        </div>
        <div
          className={
            "flex flex-wrap justify-space-evenly flex-items-end sidebar-buttons-wrapper"
          }
        >
          <div
            onClick={() => handleLogout()}
            className={"logout-button action-button animated"}
          >
            <img className="logout-icon" src={Logout} alt={"logout button"} />
          </div>
          {user?.companyId === companyId ? (
            <>
              <div
                className={`animated sidebar-delete-button action-button ${
                  showDelete && "button-clicked"
                }`}
                onClick={() => setShowDelete(!showDelete)}
              >
                <img src={Remove} alt={"remove button"} />
              </div>
              <div
                onClick={() => setSelectingDataSource(true)}
                className={"upload-button animated action-button"}
              >
                <img
                  className="upload-icon"
                  src={UploadButton}
                  alt={"upload button"}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <Dialog
        isOpen={selectingDataSource}
        onRequestClose={() => setSelectingDataSource(false)}
        title={"Analyze dataset"}
        size={"large"}
        tooltip={selectDataSourceTooltipText}
      >
        <div className="data-source-dialog">
          <div className="data-source-header">Select data source:</div>
          <div className="data-sources">
            <div
              onClick={() => {
                setUploadingCsv(true);
                setSelectingDataSource(false);
              }}
              className={"data-source"}
            >
              <img className="data-source-icon" src={Csv} alt={"CSV icon"} />
            </div>
            <div
              onClick={() => {
                setSelectingDataSource(false);
                setUploadingGoogleReviews(true);
              }}
              className={"data-source animated pointer"}
            >
              <img
                className="data-source-icon"
                src={GoogleReviews}
                alt={"Google Reviews logo"}
              />
            </div>
            <div
              onClick={() => setUploadingTrustpilot(true)}
              className={"data-source animated pointer"}
            >
              <img
                className="data-source-icon"
                src={Trustpilot}
                alt={"Trustpilot logo"}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={uploadingCsv}
        onRequestClose={() => setUploadingCsv(false)}
        title={"Analyze dataset"}
        size={"large"}
        tooltip={uploadCsvTooltipText}
      >
        <UploadCsv
          datasets={datasets}
          onUpload={(result) => {
            if (result && user && user.companyId) {
              setUploadingCsv(false);
              fetchDatasets(user.companyId);
            }
          }}
          mainDatasetId={mainDatasetId}
        />
      </Dialog>
      <Dialog
        isOpen={isGoogleLinked && uploadingGoogleReviews}
        onRequestClose={() => setUploadingGoogleReviews(false)}
        title={"Set up Google reviews"}
        size={"xsmall"}
        tooltip={googleUploadTooltipText}
      >
        <div>Select your Google account</div>
        <Select
          value={selectedGoogleAccount ?? ""}
          onChange={setSelectedGoogleAccount}
          options={savedGoogleAccountWithLocations.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          })}
        />

        <div className="mt-4">Select your Google location</div>
        {foundGoogleAccount ? (
          <Select
            value={selectedGoogleLocation ?? ""}
            onChange={setSelectedGoogleAccount}
            options={foundGoogleAccount.googleLocations.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            })}
          />
        ) : (
          "Please select your account first"
        )}
        <div className="mt-4">Name of dataset</div>
        <Input
          value={datasetName}
          onChange={setDatasetName}
          placeholder={"Name of dataset"}
          required={true}
          type="text"
        />
        <div
          onClick={async () => {
            if (uploading) {
              return;
            }

            if (refreshingGoogle) {
              return;
            }

            if (datasetName.trim() === "") {
              setError("Please add a name for your dataset");
              return;
            }

            if (datasets.map((dataset) => dataset.name).includes(datasetName)) {
              setError(
                `The name ${datasetName} is already used, please choose a different name`
              );
              return;
            }

            if (
              selectedGoogleAccount === "" ||
              selectedGoogleAccount === null
            ) {
              setError("Please select a google account first");
              return;
            }

            if (
              selectedGoogleLocation === "" ||
              selectedGoogleLocation === null
            ) {
              setError("Please select a google location first");
              return;
            }

            if (user?.companyId) {
              setUploading(true);
              await GoogleApi.createDataset(
                user.companyId,
                selectedGoogleAccount,
                selectedGoogleLocation,
                datasetName
              );

              fetchDatasets(user.companyId);

              setUploading(false);
            }

            setUploadingGoogleReviews(false);
            setDatasetName("");
          }}
          className={`upload-csv-button ${
            uploading || refreshingGoogle
              ? "rotating-colors"
              : "upload-csv-button animated pointer"
          } action-button pill`}
        >
          Add reviews
        </div>
        <div
          onClick={async () => {
            if (uploading) {
              return;
            }

            if (refreshingGoogle) {
              return;
            }

            setRefreshingGoogle(true);

            const result = await GoogleApi.refresh();
            if (result.length > 0) {
              setSelectedGoogleAccount(result[0].id);

              if (result[0].googleLocations.length > 0) {
                setSelectedGoogleLocation(result[0].googleLocations[0].id);
              }
            }

            setSavedGoogleAccountWithLocations(result);
            setRefreshingGoogle(false);
          }}
          className={`upload-csv-button ${
            refreshingGoogle
              ? "rotating-colors"
              : "upload-csv-button animated pointer"
          } action-button pill`}
        >
          Refresh accounts
        </div>
        <Toast text={error} fail={true} />
      </Dialog>
      <Dialog
        isOpen={!isGoogleLinked && uploadingGoogleReviews}
        onRequestClose={() => setUploadingGoogleReviews(false)}
        title={"Link Google"}
        size={"xsmall"}
        tooltip={linkGoogleTooltipText}
      >
        <div>{linkGoogleTooltipText}</div>
        <div
          onClick={async () => {
            window.location.href = await GoogleApi.getAuthorizationUrl();
          }}
          className={"upload-csv-button animated action-button pill"}
        >
          Link Google account
        </div>
      </Dialog>
      <Dialog
        isOpen={deleting !== null}
        onRequestClose={() => {
          setDeleting(null);
        }}
        title={"Delete dataset"}
        size={"small"}
      >
        <DeleteForm
          object={{ id: deleting?.id, name: deleting?.name }}
          onDelete={async (result: boolean, id: string | undefined) => {
            if (result && id && companyId) {
              try {
                await DatasetApi.deleteDataset(companyId, id);
                setDatasets(
                  datasets
                    .filter((dataset) => dataset.id !== id)
                    .map((dataset) => {
                      return {
                        ...dataset,
                        subsets: dataset.subsets.filter((s) => s.id !== id),
                      };
                    })
                );
                setDeleting(null);
                setShowDelete(false);
                setSelectedDataset(undefined);
                navigate(
                  generatePath(routes.dashboard, { companyId: companyId })
                );
              } catch (e) {
                handleError();
                setDeleting(null);
                setShowDelete(false);
              }
            }
          }}
        />
      </Dialog>
    </div>
  );
}
