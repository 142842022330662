import { routes } from "../constants/routes";
import { generatePath, Navigate, useLocation } from "react-router-dom";
import { parseToken, UserType } from "../api/AuthApi";

function redirectToStart(token: string | null) {
  try {
    const user = parseToken(token);
    if (user.type === UserType.ADMIN) {
      return <Navigate to={routes.admin} />;
    } else {
      return (
        <Navigate
          to={generatePath(routes.dashboard, { companyId: user.companyId })}
        />
      );
    }
  } catch (e) {
    // Redirect to login if token is expired
    return <Navigate to={routes.login} />;
  }
}

export function RedirectIfLoggedOut() {
  const location = useLocation();
  const token = localStorage.getItem("token");

  // Redirect to start if route is `/login` or `/`
  if ([routes.login, routes.root].includes(location.pathname)) {
    return redirectToStart(token);
  }

  // Redirect to login if route requires auth
  if (
    token === null &&
    ![routes.login, routes.resetPassword, routes.confirmAccount].includes(
      location.pathname
    )
  ) {
    return <Navigate to={routes.login} />;
  }

  return null;
}
