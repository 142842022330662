import ApiInstance from "./ApiInstance";
import { User, UserPost } from "./UserApi";

export enum UserType {
  ADMIN = "ADMIN",
  USER = "USER",
}

export function parseToken(token: string | null): {
  id: string;
  email: string;
  type: UserType;
  companyId: string;
} {
  if (token === null) {
    throw new Error("Token is either empty or expired.");
  }

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

class AuthApi {
  public async login(
    email: string,
    password: string
  ): Promise<{ token: string }> {
    const response = await ApiInstance.getApi().post("/auth/login", {
      email,
      password,
    });
    return {
      token: response.data.data.token,
    };
  }

  public logout(): void {
    localStorage.removeItem("token");
  }

  public async register(account: UserPost): Promise<User> {
    const response = await ApiInstance.getApi().post(
      "/auth/register-account",
      account
    );
    return response.data;
  }

  public async confirmPassword(
    email: string,
    password: string,
    token: string
  ): Promise<User> {
    const response = await ApiInstance.getApi().post("/auth/confirm", {
      email,
      password,
      token,
    });
    return response.data;
  }

  public async ResetPassword(email: string): Promise<void> {
    const response = await ApiInstance.getApi().post(
      "/auth/request-password-reset",
      {
        email,
      }
    );
    return response.data;
  }
}

export default new AuthApi();
