import { ReactElement } from "react";

export function Select({
  value,
  options,
  onChange,
  disabled,
}: {
  value: string;
  onChange: (value: string) => void;
  options: { label: string; value: string; img?: ReactElement }[];
  disabled?: boolean;
}) {
  return (
    <select
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={"input-field"}
      disabled={disabled}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.img}
          {option.label}
        </option>
      ))}
    </select>
  );
}
