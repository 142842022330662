import ApiInstance from "./ApiInstance";

export type Company = {
  id: string;
  name: string;
  domain: CompanyDomain;
};

export type CompanyPost = {
  name: string;
  domain: CompanyDomain;
};

export enum CompanyDomain {
  AIRLINE = "AIRLINE",
  FMCG_RETAIL = "FMCG_RETAIL",
  GENERAL = "GENERAL",
  HOTEL = "HOTEL",
  RESTAURANT = "RESTAURANT",
  TELECOM = "TELECOM",
}

class CompanyApi {
  public async getCompanies(): Promise<Company[]> {
    const response = await ApiInstance.getApi().get("/company/");
    return response.data.data;
  }

  public async deleteCompany(companyId: string): Promise<void> {
    await ApiInstance.getApi().delete(`/company/${companyId}`);
  }

  public async putCompany(companyId: string, data: Company): Promise<void> {
    await ApiInstance.getApi().put(`/company/${companyId}`, data);
  }

  public async postCompany(data: CompanyPost): Promise<Company> {
    const response = await ApiInstance.getApi().post("/company/", data);
    return response.data;
  }
}

export default new CompanyApi();
