import { PropsWithChildren, ReactElement } from "react";
import ReactModal from "react-modal";
import "./Dialog.css";
import { AiOutlineClose } from "react-icons/ai";
import TooltipIcon from "../../assets/question-mark.svg";
import { Tooltip } from "react-tooltip";

export default function Dialog({
  isOpen,
  children,
  onRequestClose,
  title,
  size,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  tooltip,
}: PropsWithChildren<{
  isOpen: boolean;
  onRequestClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  title: string | ReactElement;
  size: "small" | "wide" | "xsmall" | "large" | "side" | "msmall";
  tooltip?: string;
}>) {
  const classNames = ["dialog-body"];
  if (size === "small") {
    classNames.push("is-small");
  }
  if (size === "wide") {
    classNames.push("is-wide");
  }
  if (size === "side") {
    classNames.push("is-side");
  }
  if (size === "xsmall") {
    classNames.push("is-xsmall");
  }
  if (size === "large") {
    classNames.push("is-large");
  }
  if (size === "msmall") {
    classNames.push("is-msmall");
  }

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={window.document.getElementById("root") as HTMLElement}
      onRequestClose={onRequestClose}
      className={classNames.join(" ")}
      overlayClassName={`dialog-overlay ${size === "side" ? "is-side" : ""}`}
      bodyOpenClassName="dialog-open"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <div className={"dialog-header items-center"}>
        <div className="flex items-center">
          <div className="dialog-title">{title}</div>
          {tooltip ? (
            <>
              <div
                className={"ml-2 flex items-center align-items-baseline"}
                data-tooltip-html={tooltip}
                data-tooltip-id="csv-form-tooltip"
                data-tooltip-place="top"
              >
                <img src={TooltipIcon} alt={"Dialog tooltip icon"} />
              </div>
              <Tooltip id="csv-form-tooltip" className={"tooltip"} />
            </>
          ) : null}
        </div>
        <div
          className="close-button ml-auto"
          title="Close dialog"
          onClick={() => {
            onRequestClose();
          }}
        >
          <AiOutlineClose />
        </div>
      </div>
      <div className="dialog-content">{isOpen ? children : null}</div>
    </ReactModal>
  );
}
