import { useEffect, useState } from "react";
import { Input } from "../input/Input";
import "../user-form/UserForm.css";
import { Toast } from "../toast/Toast";
import CompanyApi, {
  Company,
  CompanyDomain,
  CompanyPost,
} from "../../api/CompanyApi";
import { Select } from "../Select";

export function CompanyForm(props: {
  company: Company | null;
  onClose: (result: boolean, company: Company | null) => void;
}) {
  const { company, onClose } = props;
  const [name, setName] = useState<string>("");
  const [domain, setDomain] = useState<CompanyDomain>(CompanyDomain.GENERAL);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [toast, setToast] = useState<{ text: string; fail: boolean }>({
    text: "",
    fail: false,
  });

  const updateForm = company !== null;

  useEffect(() => {
    if (company) {
      setName(company.name);
      setDomain(company.domain);
    } else {
      setDomain(CompanyDomain.GENERAL);
    }
  }, [company]);

  const formAction = async () => {
    try {
      if (updateForm) {
        const data: Company = {
          id: company.id,
          name: name,
          domain: domain,
        };
        await CompanyApi.putCompany(company.id, data);
        return { result: true, company: data };
      } else {
        const data: CompanyPost = {
          name: name,
          domain: domain,
        };
        const company = await CompanyApi.postCompany(data);
        return { result: true, company: company };
      }
    } catch (e) {
      setToast({ text: "Something went wrong", fail: true });
      setTimeout(() => setToast({ text: "", fail: false }), 3000);
      return { result: false };
    }
  };

  const onCompanyChange = async (companyDomain: string) => {
    setDomain(companyDomain as CompanyDomain);
  };

  return (
    <div className={"flex justify-center"}>
      <div className={"flex flex-column"}>
        <Input
          value={name}
          onChange={setName}
          placeholder={"Name"}
          required={true}
          type={"text"}
        />
        {name.length === 0 && submitted && (
          <div className={"error-text negative-margin"}>Empty name</div>
        )}
        <Select
          value={domain}
          options={Object.values(CompanyDomain).map((cd) => ({
            value: cd,
            label: cd,
          }))}
          onChange={onCompanyChange}
        />
        <div className="mt-2 align-self-center error-text">
          {toast.text.length > 0 && (
            <Toast text={toast.text} fail={toast.fail} />
          )}
        </div>
        <div className={"btn-group mt-1"}>
          <div className={"btn cancel"} onClick={() => onClose(false, null)}>
            Cancel
          </div>
          <button
            className={"btn submit"}
            type={"submit"}
            onClick={async () => {
              setSubmitted(true);
              if (name.length > 0 && domain.length > 0) {
                const { result, company } = await formAction();
                if (result && company) {
                  onClose(result, company);
                }
              }
            }}
          >
            {updateForm ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
}
