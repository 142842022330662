import { getElementAtEvent, Pie } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Context } from "chartjs-plugin-datalabels";
import { ChartColors } from "../../constants/ChartColors";
import { SentimentTypes } from "../../constants/SentimentTypes";
import { MetadataStatistic } from "../../api/DatasetApi";
import { useRef } from "react";

const style = getComputedStyle(document.body);

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function getSentimentColor(sentimentType: SentimentTypes) {
  switch (sentimentType) {
    case SentimentTypes.VERY_POSITIVE:
      return style.getPropertyValue(ChartColors.VERYPOSITIVE);
    case SentimentTypes.POSITIVE:
      return style.getPropertyValue(ChartColors.POSITIVE);
    case SentimentTypes.NEUTRAL:
      return style.getPropertyValue(ChartColors.NEUTRAL);
    case SentimentTypes.NEGATIVE:
      return style.getPropertyValue(ChartColors.NEGATIVE);
    case SentimentTypes.VERY_NEGATIVE:
      return style.getPropertyValue(ChartColors.VERYNEGATIVE);
    default:
      return style.getPropertyValue(ChartColors.NEUTRAL);
  }
}

export function PieChart({
  labels,
  datapoints,
  allData,
  setSentencesData,
}: {
  labels: string[];
  datapoints: number[];
  allData: MetadataStatistic[];
  setSentencesData: (data: MetadataStatistic[]) => void;
}) {
  const input = {
    labels: labels,
    data: datapoints,
  };

  const colors = input.data.map((_, index) => {
    return getSentimentColor(input.labels[index] as SentimentTypes);
  });

  const options = {
    plugins: {
      legend: {
        position: "left" as const,
        align: "start" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          pointStyleWidth: 40,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: style.getPropertyValue(ChartColors.BACKGROUND),
        usePointStyle: true,
        boxPadding: 4,
        titleFont: {
          size: 12,
        },
        titleColor: style.getPropertyValue(ChartColors.TEXT),
        bodyFont: {
          size: 12,
        },
        bodyColor: style.getPropertyValue(ChartColors.TEXT),
        xAlign: "left" as const,
      },
      datalabels: {
        anchor: "end" as const,
        align: "end" as const,
        offset: 5,
        display: function (context: Context) {
          return context.dataset.data[context.dataIndex] !== 0;
        },
      },
    },
    hoverOffset: 20,
    layout: {
      padding: {
        top: 30,
        bottom: 30,
        right: 30,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const data = {
    labels: input.labels,
    datasets: [
      {
        data: input.data.map(Math.abs),
        backgroundColor: colors,
      },
    ],
  };

  const chartRef = useRef();
  const onClick = (event: any) => {
    if (!chartRef.current) {
      return;
    }

    const elements = getElementAtEvent(chartRef.current, event);

    if (elements.length === 0) {
      return;
    }

    const labelIndex = elements[0].index;
    const sentiment = labels[labelIndex].toLowerCase();

    const filteredData = allData.filter(
      (d) => d.sentiment.toLowerCase() === sentiment
    );

    setSentencesData(filteredData);
  };

  return (
    <Pie ref={chartRef} onClick={onClick} data={data} options={options}></Pie>
  );
}
