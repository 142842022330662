import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GoogleApi from "../../api/GoogleApi";
import { routes } from "../../constants/routes";

export function Google() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get("code");

    if (!code) {
      navigate(routes.root);
    } else {
      GoogleApi.authorize(code).then((_) => {
        navigate(routes.root);
      });
    }
  }, []);

  return (
    <div className="flex mt-4">
      <div className="ml-auto mr-auto">
        Please wait a moment while we redirect you...
      </div>
    </div>
  );
}
