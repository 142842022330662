import { createContext } from "react";
import { User } from "../api/UserApi";

export const UserContext = createContext<{
  user: User | null;
  updateUser: () => void;
}>({
  user: null,
  updateUser: () => {
    return;
  },
});
