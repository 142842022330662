import ApiInstance from "./ApiInstance";

export type MetadataStatistic = {
  id: string;
  datasetMetadataId: string;
  aspect: string;
  topic: string;
  subTopic: string;
  sentiment: string;
  emotion: string;
  sentence: string;
  date: string;
};

export type DatasetMetadata = {
  id: string;
  datasetName: string;
  originalURL: string;
  analyzedURL?: string;
  reviewColumn: number;
  uploadedAt: string;
  companyId: string;
  statistics: MetadataStatistic[];
  delimiter: string;
  mainDatasetMetadataId?: string;
  mainDatasetName: string;
};

class DatasetApi {
  public async uploadDataset(
    companyId: string,
    form: FormData
  ): Promise<string> {
    const response = await ApiInstance.getApi().post(
      `/dataset/${companyId}`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }

  public async getDatasets(companyId: string): Promise<DatasetMetadata[]> {
    const response = await ApiInstance.getApi().get(`/dataset/${companyId}`);
    return response.data.data;
  }

  public async getDataset(
    companyId: string,
    datasetId: string
  ): Promise<DatasetMetadata> {
    const response = await ApiInstance.getApi().get(
      `/dataset/${companyId}/${datasetId}`
    );
    return response.data.data;
  }

  public async getAllDatasets(companyId: string): Promise<DatasetMetadata[]> {
    const response = await ApiInstance.getApi().get(
      `/dataset/${companyId}/all`
    );
    return response.data.data;
  }

  public async deleteDataset(
    companyId: string,
    datasetId: string
  ): Promise<void> {
    await ApiInstance.getApi().delete(`/dataset/${companyId}/${datasetId}`);
  }
}

export default new DatasetApi();
