import { useEffect, useState } from "react";
import { Input } from "../input/Input";
import userApi, { User, UserPost } from "../../api/UserApi";

import "./UserForm.css";
import { Toast } from "../toast/Toast";
import authApi, { UserType } from "../../api/AuthApi";
import { Select } from "../Select";
import { Company } from "../../api/CompanyApi";
import { useEffectOnce } from "react-use";

export function UserForm(props: {
  user: User | null;
  companies: Company[];
  adminCompanyId: string | null;
  onClose: (result: boolean, user: User | null) => void;
}) {
  const { user, companies, adminCompanyId, onClose } = props;
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [toast, setToast] = useState<{ text: string; fail: boolean }>({
    text: "",
    fail: false,
  });
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");

  const updateForm = user !== null;

  useEffectOnce(() => {
    if (!user && companies.length > 0) {
      setSelectedCompanyId(companies[0].id);
    }
  });

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setName(user.name);
      if (user.companyId) {
        setSelectedCompanyId(user.companyId);
      }
    }
  }, [user]);

  const formAction = async () => {
    try {
      if (updateForm) {
        const data: User = {
          id: user.id,
          name: name,
          email: email,
          userType: user.userType,
          status: user.status,
          companyId: selectedCompanyId,
        };
        await userApi.putUser(user.id, data);
        return { result: true, user: data };
      } else {
        const userPost: UserPost = {
          name: name,
          email: email,
          userType: UserType.USER,
          companyId: selectedCompanyId,
        };
        const data = await authApi.register(userPost);
        return { result: true, user: data };
      }
    } catch (e) {
      setToast({ text: "Something went wrong", fail: true });
      setTimeout(() => setToast({ text: "", fail: false }), 3000);
      return { result: false };
    }
  };

  return (
    <div className={"flex justify-center"}>
      <div className={"flex flex-column padding-right"}>
        <Input
          value={name}
          onChange={setName}
          placeholder={"Name"}
          required={true}
          type={"text"}
        />
        {name.length === 0 && submitted && (
          <div className={"error-text negative-margin"}>Empty name</div>
        )}
        <Input
          value={email}
          onChange={setEmail}
          placeholder={"Email"}
          required={true}
          type={"text"}
          disabled={updateForm}
        />
        {email.length === 0 && submitted && (
          <div className={"error-text negative-margin"}>Empty email</div>
        )}
        <div>
          {!(user && user.userType === UserType.ADMIN) ? (
            <Select
              value={selectedCompanyId}
              options={companies
                .filter((company) => company.id !== adminCompanyId)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((c) => ({
                  value: c.id,
                  label: c.name,
                }))}
              onChange={setSelectedCompanyId}
            />
          ) : null}
        </div>
        <div className="mt-2 align-self-center error-text">
          {toast.text.length > 0 && (
            <Toast text={toast.text} fail={toast.fail} />
          )}
        </div>
        <div className={"btn-group mt-1"}>
          <div className={"btn cancel"} onClick={() => onClose(false, null)}>
            Cancel
          </div>
          <button
            className={"btn submit"}
            type={"submit"}
            onClick={async () => {
              setSubmitted(true);
              if (
                name.length > 0 &&
                email.length > 0 &&
                (selectedCompanyId.length > 0 ||
                  (user && user.userType === UserType.ADMIN))
              ) {
                const { result, user } = await formAction();
                if (result && user) {
                  onClose(result, user);
                }
              }
            }}
          >
            {updateForm ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
}
