import AuthApi, { parseToken, UserType } from "../../api/AuthApi";
import axios from "axios";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { FormWindow } from "../../components/form-window/FormWindow";
import { Fragment, useState } from "react";
import { Input } from "../../components/input/Input";
import user from "../../assets/user.svg";
import lock from "../../assets/lock.svg";
import { Toast } from "../../components/toast/Toast";
import "./Login.css";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRevealedPassword, setIsRevealedPassword] = useState(false);
  const [toast, setToast] = useState("");
  const navigate = useNavigate();

  async function onSubmitLogin(email: string, password: string): Promise<void> {
    try {
      const { token } = await AuthApi.login(email, password);
      localStorage.setItem("token", token);
      const user: {
        id: string;
        email: string;
        type: UserType;
        companyId: string;
      } = parseToken(token);

      if (user.type === UserType.ADMIN) {
        navigate(routes.admin);
      } else {
        navigate(generatePath(routes.dashboard, { companyId: user.companyId }));
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        setToast("Wrong credentials" as string);
        setTimeout(() => setToast(""), 3000);
      } else {
        setToast("Something went wrong" as string);
        setTimeout(() => setToast(""), 3000);
      }
    }
  }

  return (
    <div className="login-upper-container flex">
      <div className="flex flex-column login-container ">
        <div className="title-container flex">
          <label className="login-title">AlfaSent</label>
        </div>
        <div className="flex flex-column login-input-container ">
          <img className={"account-icon"} src={user} alt="account icon" />
          <FormWindow
            onSubmit={async () => {
              await onSubmitLogin(email, password);
            }}
            form={
              <Fragment>
                <Input
                  value={email}
                  onChange={setEmail}
                  placeholder={"Email"}
                  required={true}
                  type="email"
                  image={user}
                />
                <Input
                  value={password}
                  onChange={setPassword}
                  placeholder={"Password"}
                  required={true}
                  type={isRevealedPassword ? "text" : "password"}
                  image={lock}
                  showPasswordIcon={true}
                  isRevealedPassword={isRevealedPassword}
                  setIsRevealedPassword={setIsRevealedPassword}
                />
                <button className="button mt-4" type="submit">
                  {"Login"}
                </button>
                <Link className="password-reset-link" to={routes.resetPassword}>
                  {"Forgot password?"}
                </Link>
                <div className="mt-4 login-toast">
                  {toast.length > 0 && <Toast text={toast} fail={true} />}
                </div>
              </Fragment>
            }
          />
        </div>
      </div>
    </div>
  );
}
