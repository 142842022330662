import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Fragment, useRef } from "react";
import { MetadataStatistic } from "../../api/DatasetApi";

ChartJS.register(
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const style = getComputedStyle(document.body);

export function HorizontalBarChart({
  labels,
  datapoints,
  allData,
  setSentencesData,
}: {
  labels: string[];
  datapoints: number[][];
  allData: MetadataStatistic[];
  setSentencesData: (data: MetadataStatistic[]) => void;
}) {
  const colors = [
    "--negative-chart",
    "--very-negative-chart",
    "--neutral-chart",
    "--positive-chart",
    "--very-positive-chart",
  ];

  const input = [
    {
      data: datapoints.map((datapoint) => datapoint[1]),
      feeling: "Negative",
      fullData: datapoints,
    },
    {
      data: datapoints.map((datapoint) => datapoint[0]),
      feeling: "Very negative",
      fullData: datapoints,
    },
    {
      data: datapoints.map((datapoint) => datapoint[2]),
      feeling: "Neutral",
      fullData: datapoints,
    },
    {
      data: datapoints.map((datapoint) => datapoint[3]),
      feeling: "Positive",
      fullData: datapoints,
    },
    {
      data: datapoints.map((datapoint) => datapoint[4]),
      feeling: "Very positive",
      fullData: datapoints,
    },
  ];

  const options = {
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          pointStyleWidth: 40,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: style.getPropertyValue("--table-gray"),
        usePointStyle: true,
        boxPadding: 4,
        titleFont: {
          size: 12,
        },
        titleColor: style.getPropertyValue("--light-gray"),
        bodyFont: {
          size: 12,
        },
        bodyColor: style.getPropertyValue("--light-gray"),
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    indexAxis: "y" as const,
    interaction: {
      mode: "y" as const,
    },
    responsive: true,
    maintainAspectRatio: false,
    borderRadius: 6,
  };

  const dataset = input.map((el, i) => {
    return {
      label: el.feeling,
      data: el.data,
      backgroundColor: style.getPropertyValue(colors[i]),
      order: i,
      datalabels: {
        display: false,
      },
    };
  });

  const data = {
    labels: labels,

    datasets: dataset,
  };

  const chartRef = useRef();
  const onClick = (event: any) => {
    if (!chartRef.current) {
      return;
    }

    const elements = getElementAtEvent(chartRef.current, event);

    if (elements.length === 0) {
      return;
    }

    const datasetIndex = elements[0].datasetIndex;
    const labelIndex = elements[0].index;
    const topic = labels[labelIndex].toLowerCase().replaceAll("_", " ");
    const sentiment = input[datasetIndex].feeling
      .toLowerCase()
      .replaceAll("_", " ");
    const filteredData = allData.filter(
      (d) =>
        d.topic.toLowerCase().replaceAll("_", " ") === topic &&
        d.sentiment.toLowerCase().replaceAll("_", " ") === sentiment
    );

    setSentencesData(filteredData);
  };

  return (
    <Fragment>
      <Bar onClick={onClick} ref={chartRef} options={options} data={data}></Bar>
    </Fragment>
  );
}
