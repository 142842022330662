import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { ChartColors } from "../../constants/ChartColors";
import { MetadataStatistic } from "../../api/DatasetApi";
import { useRef } from "react";

ChartJS.register(
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
);

const style = getComputedStyle(document.body);

export function VerticalBarChart({
  labels,
  input,
  allData,
  setSentencesData,
}: {
  labels: string[];
  input: number[];
  allData: MetadataStatistic[];
  setSentencesData: (data: MetadataStatistic[]) => void;
}) {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: style.getPropertyValue(ChartColors.BACKGROUND),
        usePointStyle: true,
        boxPadding: 4,
        titleFont: {
          size: 12,
        },
        titleColor: style.getPropertyValue(ChartColors.TEXT),
        bodyFont: {
          size: 12,
        },
        bodyColor: style.getPropertyValue(ChartColors.TEXT),
        xAlign: "left" as const,
        yAlign: "bottom" as const,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
    borderRadius: 6,
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: input,
        backgroundColor: style.getPropertyValue(ChartColors.LIGHT_BLUE),
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const chartRef = useRef();
  const onClick = (event: any) => {
    if (!chartRef.current) {
      return;
    }

    const elements = getElementAtEvent(chartRef.current, event);

    if (elements.length === 0) {
      return;
    }

    const labelIndex = elements[0].index;
    const topicSubtopic = labels[labelIndex].toLowerCase();

    const filteredData = allData.filter(
      (d) =>
        `${d.topic.toLowerCase()}-${d.subTopic.toLowerCase()}` === topicSubtopic
    );

    setSentencesData(filteredData);
  };

  return (
    <Bar ref={chartRef} onClick={onClick} options={options} data={data}></Bar>
  );
}
