import ApiInstance from "./ApiInstance";

export type SavedGoogleAccountWithLocations = {
  name: string;
  id: string;
  googleLocations: {
    name: string;
    id: string;
  }[];
};

class GoogleApi {
  public async getAuthorizationUrl(): Promise<string> {
    const response = await ApiInstance.getApi().get("/google/authorizationUrl");
    return response.data.url;
  }

  public async authorize(code: string): Promise<void> {
    await ApiInstance.getApi().post(`/google/authorize`, {
      code: code,
    });
  }

  public async isConnected(): Promise<boolean> {
    const response = await ApiInstance.getApi().get(`/google/isConnected`);
    return response.data.ok;
  }

  public async getDetails(): Promise<SavedGoogleAccountWithLocations[]> {
    const response = await ApiInstance.getApi().get("/google/details");
    return response.data.data;
  }

  public async refresh(): Promise<SavedGoogleAccountWithLocations[]> {
    const response = await ApiInstance.getApi().post("/google/refresh");
    return response.data.data;
  }

  public async createDataset(
    companyId: string,
    accountId: string,
    locationId: string,
    name: string
  ) {
    await ApiInstance.getApi().post(`/google/${companyId}/dataset`, {
      accountId,
      locationId,
      name,
    });
  }
}

export default new GoogleApi();
