import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { ChartColors } from "../../constants/ChartColors";
import { MetadataStatistic } from "../../api/DatasetApi";
import { SentimentTypes } from "../../constants/SentimentTypes";
import { DateTime } from "luxon";

ChartJS.register(Title, Tooltip, PointElement, LineElement);

const style = getComputedStyle(document.body);

export function Timeline({ statistics }: { statistics: MetadataStatistic[] }) {
  const labels = Array.from(new Set(statistics.map((s) => s.date))).sort(
    (a, b) => {
      return DateTime.fromFormat("dd/MM/YYYY", a) <=
        DateTime.fromFormat("dd/MM/YYYY", b)
        ? -1
        : 1;
    }
  );

  const values: Record<string, string[]> = {};

  for (const label of labels) {
    values[label] = statistics
      .filter((s) => s.date === label)
      .map((s) => s.sentiment);
  }

  const colors = [
    ChartColors.VERYPOSITIVE,
    ChartColors.POSITIVE,
    ChartColors.NEUTRAL,
    ChartColors.NEGATIVE,
    ChartColors.VERYNEGATIVE,
  ];

  const input = [
    {
      data: Object.values(values).map(
        (value) =>
          value.filter((v) => v === SentimentTypes.VERY_POSITIVE).length
      ),
      feeling: "Very positive",
    },
    {
      data: Object.values(values).map(
        (value) => value.filter((v) => v === SentimentTypes.POSITIVE).length
      ),
      feeling: "Positive",
    },
    {
      data: Object.values(values).map(
        (value) => value.filter((v) => v === SentimentTypes.NEUTRAL).length
      ),
      feeling: "Neutral",
    },
    {
      data: Object.values(values).map(
        (value) => value.filter((v) => v === SentimentTypes.NEGATIVE).length
      ),
      feeling: "Negative",
    },
    {
      data: Object.values(values).map(
        (value) =>
          value.filter((v) => v === SentimentTypes.VERY_NEGATIVE).length
      ),
      feeling: "Very negative",
    },
  ];

  const options = {
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          pointStyleWidth: 40,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: style.getPropertyValue(ChartColors.BACKGROUND),
        usePointStyle: true,
        boxPadding: 4,
        titleFont: {
          size: 12,
        },
        titleColor: style.getPropertyValue(ChartColors.TEXT),
        bodyFont: {
          size: 12,
        },
        bodyColor: style.getPropertyValue(ChartColors.TEXT),
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index" as const,
    },
  };

  const data = {
    labels: labels,
    datasets: input.map((el, i) => {
      return {
        label: el.feeling,
        data: el.data,
        borderColor: style.getPropertyValue(colors[i]),
        backgroundColor: style.getPropertyValue(colors[i]),
        tension: 0.1,
        datalabels: {
          display: false,
        },
      };
    }),
  };

  return <Line options={options} data={data}></Line>;
}
