import "../../index.css";
import "./UploadCsv.css";
import { Fragment, useState } from "react";
import { Input } from "../input/Input";
import { CsvInput } from "../csv-input/CsvInput";
import { Toast } from "../toast/Toast";
import DatasetApi from "../../api/DatasetApi";
import { useUserContext } from "../../hooks/useUserContext";
import { ColumnLabeler } from "../column-labeler/ColumnLabeler";
import { Select } from "../Select";
import TooltipIcon from "../../assets/question-mark.svg";
import { Tooltip } from "react-tooltip";

export function UploadCsv(prop: {
  onUpload: (result: boolean) => void;
  datasets: { name: string; id: string }[];
  mainDatasetId?: string;
}) {
  const languages = [
    { full: "Dutch", code: "nl" },
    { full: "English", code: "en" },
    { full: "French", code: "fr" },
    { full: "German", code: "de" },
  ];

  const { onUpload, datasets, mainDatasetId } = prop;
  const { user } = useUserContext();
  const [toast, setToast] = useState<string>("");
  const [datasetName, setDatasetName] = useState<string>("");
  const [mainDataSetName, setMainDataSetName] = useState<string>("");
  const [csv, setCsv] = useState<File | null>(null);
  const [reviewColumn, setReviewColumn] = useState<number | undefined>(
    undefined
  );
  const [langColumn, setLangColumn] = useState<number | undefined>(undefined);
  const [isSelectingLangColumn, setIsSelectingLangColumn] = useState(false);
  const [hasNoLangColumn, setHasNoLangColumn] = useState(false);
  const [language, setLanguage] = useState<string>(languages[0].code);
  const [dateColumn, setDateColumn] = useState<number | undefined>();
  const [isSelectingDateColumn, setIsSelectingDateColumn] = useState(false);
  const [hasNoDateColumn, setHasNoDateColumn] = useState(false);
  const [generalDate, setGeneralDate] = useState<string | undefined>();
  const [headerRow, setHeaderRow] = useState<boolean>(true);
  const [uploading, setUploading] = useState<boolean>(false);
  const [delimiter, setDelimiter] = useState(";");
  const [csvData, setCsvData] = useState<string[][]>([]);

  const handleUpload = async () => {
    if (!datasetName) {
      setToastText("Please enter a name for the dataset");
    } else if (datasets.map((dataset) => dataset.name).includes(datasetName)) {
      setToastText(
        `The name ${datasetName} is already used, please choose a different name`
      );
    } else if (!csv) {
      setToastText("Please provide a csv file");
    } else if (!reviewColumn?.toString()) {
      setToastText("Please select the column containing the reviews");
    } else if (!language) {
      setToastText("Please choose a language");
    } else {
      let csvFile = csv;
      if (headerRow) {
        csvFile = (await stripFirstRowFromFile(csv)) || csv;
      }

      if (dateColumn !== undefined) {
        const faultyRecords = csvData.filter((r: any) => {
          try {
            return r[dateColumn].match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/gm);
          } catch (e) {
            return false;
          }
        });

        if (faultyRecords.length === 0) {
          setToastText(
            "Not all dates are in the correct format. Please use dd/mm/yyyy"
          );

          return;
        }
      }

      const formData = new FormData();
      formData.append("datasetName", datasetName);
      formData.append("mainDatasetName", mainDataSetName ?? datasetName);
      formData.append("reviewColumn", reviewColumn.toString());
      formData.append("language", language);
      formData.append("delimiter", delimiter);

      if (mainDatasetId) {
        formData.append("mainDatasetId", mainDatasetId);
      }

      if (langColumn !== undefined && !hasNoLangColumn) {
        formData.append("languageColumn", langColumn.toString());
      }

      if (dateColumn !== undefined && !hasNoDateColumn) {
        formData.append("dateColumn", dateColumn.toString());
      }

      if (generalDate !== undefined && generalDate !== "") {
        formData.append("generalDate", generalDate.toString());
      }

      formData.append("dataset", csvFile);

      setUploading(true);
      if (user?.companyId) {
        try {
          await DatasetApi.uploadDataset(user.companyId, formData);
          onUpload(true);
        } catch (e) {
          setToastText("Something went wrong while uploading");
          setUploading(false);
        }
      }
    }
  };

  async function stripFirstRowFromFile(inputFile: File): Promise<File | null> {
    return new Promise<File | null>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        if (event.target?.result) {
          const contents: string = event.target.result as string;
          const lines: string[] = contents.split("\n");

          if (lines.length <= 1) {
            // The file has only one or no rows, nothing to strip
            resolve(null);
            return;
          }

          const newContents = lines.slice(1).join("\n"); // Join all lines except the first

          // Create a new Blob from the modified contents
          const newFile = new File([newContents], inputFile.name, {
            type: inputFile.type,
          });

          resolve(newFile);
        }
      };

      reader.onerror = (event) => {
        reject(event.target?.error || new Error("Failed to read the file."));
      };

      reader.readAsText(inputFile);
    });
  }

  function setToastText(text: string) {
    setToast(text);
    setTimeout(() => {
      setToast("");
    }, 5000);
  }

  return (
    <div className={"flex flex-column upload-csv-wrapper"}>
      {!mainDatasetId ? (
        <label className={"flex items-center mb-2"}>
          <span className={"mr-1"}>Group name </span>
          <Input
            value={mainDataSetName}
            onChange={setMainDataSetName}
            placeholder={"Group name"}
            required={true}
            type="text"
            className={"input-datasetname"}
          />
        </label>
      ) : null}
      <label className={"flex items-center mb-2"}>
        <span className={"mr-1"}>Name </span>
        <Input
          value={datasetName}
          onChange={setDatasetName}
          placeholder={"Name of dataset"}
          required={true}
          type="text"
          className={"input-datasetname"}
        />
      </label>
      <div className={`flex ${!csv && "flex-column"}`}>
        <label className={`flex items-center mb-2 flex-1`}>
          <span className={"mr-1"}>File </span>
          <CsvInput onChange={setCsv} />
        </label>
      </div>
      {csv ? (
        <Fragment>
          <label className={"flex items-center mb-2"}>
            <span className={"mr-1 flex"}>
              <div>Delimiter</div>
              <img
                className={"ml-2"}
                data-tooltip-html={
                  "The field separator (comma or semicolon) that separates the values in the CSV."
                }
                data-tooltip-id="delimiter-tooltip"
                data-tooltip-place="top"
                src={TooltipIcon}
                alt={"Delimiter tooltip icon"}
              />
              <Tooltip id="delimiter-tooltip" className={"tooltip"} />
            </span>
            <Select
              value={delimiter}
              onChange={setDelimiter}
              options={[
                { value: ";", label: ";" },
                { value: ",", label: "," },
              ]}
            />
          </label>
          <label>
            <input
              className={"header-check"}
              type={"checkbox"}
              onChange={() => setHasNoLangColumn(!hasNoLangColumn)}
              defaultChecked={false}
            />
            Has no language column
          </label>
          {hasNoLangColumn ? (
            <label className={"flex items-center mb-2 flex-1"}>
              <span className={"mr-2"}>Language </span>
              <select
                onChange={(e) => setLanguage(e.target.value)}
                className={"input-field"}
              >
                {languages.map((language) => {
                  return <option value={language.code} label={language.full} />;
                })}
              </select>
            </label>
          ) : null}
          <label>
            <input
              className={"header-check"}
              type={"checkbox"}
              onChange={() => setHasNoDateColumn(!hasNoDateColumn)}
              defaultChecked={false}
            />
            Has no date column
          </label>
          {hasNoDateColumn ? (
            <label className={"flex items-center mb-2 flex-1"}>
              <span className={"mr-2"}>Date </span>
              <Input
                value={generalDate ?? ""}
                onChange={setGeneralDate}
                placeholder={"Date"}
                required={true}
                type="date"
                className={"input-datasetname"}
              />
            </label>
          ) : null}
        </Fragment>
      ) : null}

      {csv && !isSelectingLangColumn && !isSelectingDateColumn ? (
        <div className={"mt-1 labeler-wrapper"}>
          <ColumnLabeler
            csv={csv}
            reviewColumn={reviewColumn}
            setreviewColumn={setReviewColumn}
            headerRow={headerRow}
            setHeaderRow={setHeaderRow}
            delimiter={delimiter}
            isLangPreview={false}
            isDatePreview={false}
            setCsvData={setCsvData}
          />
        </div>
      ) : null}

      {csv && isSelectingLangColumn && !hasNoLangColumn ? (
        <div className={"mt-1 labeler-wrapper"}>
          <ColumnLabeler
            csv={csv}
            reviewColumn={langColumn}
            setreviewColumn={setLangColumn}
            headerRow={headerRow}
            setHeaderRow={setHeaderRow}
            delimiter={delimiter}
            isLangPreview={true}
            isDatePreview={false}
            setCsvData={setCsvData}
          />
        </div>
      ) : null}

      {csv && isSelectingDateColumn && !hasNoDateColumn ? (
        <div className={"mt-1 labeler-wrapper"}>
          <ColumnLabeler
            csv={csv}
            reviewColumn={dateColumn}
            setreviewColumn={setDateColumn}
            headerRow={headerRow}
            setHeaderRow={setHeaderRow}
            delimiter={delimiter}
            isLangPreview={false}
            isDatePreview={true}
            setCsvData={setCsvData}
          />
        </div>
      ) : null}

      {csv &&
        !isSelectingLangColumn &&
        !hasNoLangColumn &&
        !isSelectingDateColumn && (
          <div
            className={`align-self-center ${
              uploading
                ? "rotating-colors"
                : "upload-csv-button animated pointer"
            }`}
            onClick={() => {
              if (reviewColumn === undefined) {
                setToastText("Please select a review column first");
                return;
              }

              setIsSelectingLangColumn(true);
            }}
          >
            Next
          </div>
        )}

      {csv &&
        (isSelectingLangColumn ||
          hasNoLangColumn ||
          isSelectingDateColumn ||
          hasNoDateColumn) && (
          <div className="flex justify-center">
            {isSelectingLangColumn || isSelectingDateColumn ? (
              <div
                className={`align-self-center ${
                  uploading
                    ? "rotating-colors"
                    : "upload-csv-button animated pointer"
                }`}
                onClick={() => {
                  if (isSelectingLangColumn) {
                    setIsSelectingLangColumn(false);
                  } else if (isSelectingDateColumn) {
                    setIsSelectingLangColumn(!hasNoLangColumn);
                    setIsSelectingDateColumn(false);
                  }
                }}
              >
                Back
              </div>
            ) : null}
            {csv &&
              (isSelectingLangColumn || hasNoLangColumn) &&
              !hasNoDateColumn &&
              !isSelectingDateColumn && (
                <div
                  className={`align-self-center ml-4 ${
                    uploading
                      ? "rotating-colors"
                      : "upload-csv-button animated pointer"
                  }`}
                  onClick={() => {
                    if (langColumn === undefined && !hasNoLangColumn) {
                      setToastText("Please select a language column first");
                      return;
                    }

                    setIsSelectingDateColumn(true);
                    setIsSelectingLangColumn(false);
                  }}
                >
                  Next
                </div>
              )}
            {isSelectingDateColumn ||
            (isSelectingLangColumn && hasNoDateColumn) ||
            (!isSelectingLangColumn &&
              !isSelectingDateColumn &&
              hasNoDateColumn) ? (
              <div
                className={`align-self-center ${
                  isSelectingLangColumn || isSelectingDateColumn ? "ml-4" : ""
                } ${
                  uploading
                    ? "rotating-colors"
                    : "upload-csv-button animated pointer"
                }`}
                onClick={handleUpload}
              >
                {uploading ? "Uploading..." : "Upload csv"}
              </div>
            ) : null}
          </div>
        )}

      <div className={"upload-toast align-self-center"}>
        <Toast text={toast} />
      </div>
    </div>
  );
}
