import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./constants/routes";
import { Login } from "./pages/login/Login";
import { RedirectIfLoggedOut } from "./components/RedirectIfLoggedOut";
import { ConfirmAccount } from "./pages/confirm-account/ConfirmAccount";
import { ResetPassword } from "./pages/reset-password/ResetPassword";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { AdminPage } from "./pages/admin/AdminPage";
import { RedirectIfUser } from "./components/RedirectIfUser";
import { UserProvider } from "./providers/UserProvider";
import { Google } from "./pages/google/Google";

function App() {
  return (
    <BrowserRouter>
      <RedirectIfLoggedOut />
      <Routes>
        <Route element={<RedirectIfUser />}>
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.confirmAccount} element={<ConfirmAccount />} />
          <Route path={routes.resetPassword} element={<ResetPassword />} />
          <Route path={routes.google} element={<Google />} />
          <Route element={<UserProvider />}>
            <Route path={routes.dashboard} element={<Dashboard />} />
            <Route path={routes.admin} element={<AdminPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
