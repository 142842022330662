import { generatePath, Navigate, Outlet, useLocation } from "react-router-dom";
import { routes } from "../constants/routes";
import { parseToken, UserType } from "../api/AuthApi";

export function RedirectIfUser() {
  const location = useLocation();
  const token = localStorage.getItem("token");

  if (token && location.pathname === routes.admin) {
    const user = parseToken(token);
    if (user.type !== UserType.ADMIN) {
      return (
        <Navigate
          to={generatePath(routes.dashboard, { companyId: user.companyId })}
        />
      );
    }
  }

  return <Outlet />;
}
