import "./CsvInput.css";

export function CsvInput({ onChange }: { onChange: (csv: File) => void }) {
  return (
    <input
      className="csv-input input-field"
      required={true}
      type="file"
      accept=".csv"
      onChange={({ target: { files } }) => {
        if (files !== null && files.length > 0) {
          onChange(files[0]);
        }
      }}
    />
  );
}
