import ApiInstance from "./ApiInstance";
import { UserType } from "./AuthApi";

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  CONFIRM_EMAIL = "CONFIRM_EMAIL",
  PASSWORD_RESET = "PASSWORD_RESET",
  SUSPENDED = "SUSPEND",
  REMOVED = "REMOVED",
}

export type User = {
  id: string;
  name: string;
  email: string;
  userType: UserType;
  status: AccountStatus;
  companyId: string | null;
};

export type UserPost = {
  name: string;
  email: string;
  userType: UserType;
  companyId: string | null;
};

class UserApi {
  public async getUsers(): Promise<User[]> {
    const response = await ApiInstance.getApi().get("/user/");
    return response.data;
  }

  public async deleteUser(userId: string): Promise<void> {
    await ApiInstance.getApi().delete(`/user/${userId}`);
  }

  public async putUser(userId: string, data: User): Promise<void> {
    await ApiInstance.getApi().put(`/user/${userId}`, data);
  }

  public async getAccount(): Promise<User> {
    const response = await ApiInstance.getApi().get("/user/me");
    return response.data;
  }
}

export default new UserApi();
