export enum ChartColors {
  NEGATIVE = "--negative-chart",
  VERYNEGATIVE = "--very-negative-chart",
  NEUTRAL = "--neutral-chart",
  POSITIVE = "--positive-chart",
  VERYPOSITIVE = "--very-positive-chart",
  BACKGROUND = "--table-gray",
  TEXT = "--light-gray",
  BLUE = "--blue-chart",
  DARK_BLUE = "--dark-blue-chart",
  LIGHT_BLUE = "--light-blue-chart",
}
