import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { Chart as ChartJS, Legend, Title, Tooltip } from "chart.js";
import { Context } from "chartjs-plugin-datalabels";
import { ChartColors } from "../../constants/ChartColors";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { MetadataStatistic } from "../../api/DatasetApi";
import { useRef } from "react";
import { all } from "axios";

ChartJS.register(Title, Tooltip, Legend, ChartDataLabels);

const style = getComputedStyle(document.body);

export function DonutChart({
  labels,
  datapoints,
  allData,
  setSentencesData,
}: {
  labels: string[];
  datapoints: number[];
  allData: MetadataStatistic[];
  setSentencesData: (data: MetadataStatistic[]) => void;
}) {
  const input = {
    labels: labels,
    data: datapoints,
  };

  const options = {
    plugins: {
      legend: {
        position: "left" as const,
        align: "start" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          pointStyleWidth: 40,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: style.getPropertyValue(ChartColors.BACKGROUND),
        usePointStyle: true,
        boxPadding: 4,
        titleFont: {
          size: 12,
        },
        titleColor: style.getPropertyValue(ChartColors.TEXT),
        bodyFont: {
          size: 12,
        },
        bodyColor: style.getPropertyValue(ChartColors.TEXT),
      },
    },
    hoverOffset: 20,
    layout: {
      padding: 20,
    },
    cutout: "80%",
    responsive: true,
    maintainAspectRatio: false,
  };

  const data = {
    labels: input.labels,
    datasets: [
      {
        label: "Emotions",
        data: input.data,
        backgroundColor: labels.map((item) => {
          return style.getPropertyValue(`--${item}`);
        }),
        datalabels: {
          anchor: "end" as const,
          align: "end" as const,
          offset: 5,
          display: function (context: Context) {
            return context.dataset.data[context.dataIndex] !== 0;
          },
        },
      },
    ],
  };

  const chartRef = useRef();
  const onClick = (event: any) => {
    if (!chartRef.current) {
      return;
    }

    const elements = getElementAtEvent(chartRef.current, event);

    if (elements.length === 0) {
      return;
    }

    const labelIndex = elements[0].index;
    const emotion = labels[labelIndex].toLowerCase();

    const filteredData = allData.filter(
      (d) => d.emotion.toLowerCase() === emotion
    );

    setSentencesData(filteredData);
  };

  return (
    <Doughnut
      ref={chartRef}
      onClick={onClick}
      data={data}
      options={options}
    ></Doughnut>
  );
}
