import { useState, Fragment } from "react";
import { FormWindow } from "../../components/form-window/FormWindow";
import { Input } from "../../components/input/Input";
import { Toast } from "../../components/toast/Toast";
import account from "../../assets/user.svg";
import "../login/Login.css";
import AuthApi from "../../api/AuthApi";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

export function ResetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [toast, setToast] = useState("");

  async function onSubmitConfirm(email: string): Promise<void> {
    try {
      await AuthApi.ResetPassword(email);
      navigate(routes.login);
    } catch (error) {
      setToast("Something went wrong while requesting password in!");
      setTimeout(() => setToast(""), 3000);
    }
  }

  return (
    <div className="login-upper-container flex">
      <div className="login-container flex">
        <div className="flex flex-column login-input-container">
          <div className={"sub-title"}>
            <label>Recover your password</label>
          </div>
          <FormWindow
            form={
              <Fragment>
                <Fragment>
                  <Input
                    value={email}
                    onChange={setEmail}
                    placeholder="email"
                    required={true}
                    type={"text"}
                    image={account}
                    minLength={8}
                  />
                  <button className="button mt-4" type="submit">
                    Recover password
                  </button>
                </Fragment>
                {toast.length > 0 && (
                  <div className="confirmation-toast mt-4">
                    <Toast text={toast} fail={true} />
                  </div>
                )}
              </Fragment>
            }
            onSubmit={async () => {
              if (email) await onSubmitConfirm(email);
            }}
          />
        </div>
      </div>
    </div>
  );
}
