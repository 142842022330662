import { HTMLInputTypeAttribute } from "react";
import hidePassword from "../../assets/eye.svg";
import showPassword from "../../assets/eye-slash.svg";
import "./Input.css";

export function Input({
  className,
  value,
  onChange,
  placeholder,
  required,
  type,
  image,
  alt,
  disabled,
  showPasswordIcon = false,
  isRevealedPassword,
  setIsRevealedPassword,
  minLength,
  maxLength,
  min,
  max,
}: {
  className?: string;
  value: string;
  onChange: (v: string) => void;
  placeholder: string;
  required: boolean;
  type: HTMLInputTypeAttribute;
  image?: string;
  alt?: string;
  disabled?: boolean;
  showPasswordIcon?: boolean;
  isRevealedPassword?: boolean;
  setIsRevealedPassword?: (v: boolean) => void;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
}) {
  return (
    <div className="input-with-img">
      {image ? <img className="input-img" src={image} alt={alt} /> : null}
      <input
        className={`${className ? className : ""} ${
          image ? "image" : null
        } input-field`}
        placeholder={placeholder}
        type={type}
        value={value}
        required={required}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
      />
      {showPasswordIcon ? (
        <img
          src={isRevealedPassword ? showPassword : hidePassword}
          alt={alt}
          className={"input-eye pointer"}
          onClick={() => {
            if (!setIsRevealedPassword) {
              return;
            }

            setIsRevealedPassword(!isRevealedPassword);
          }}
        />
      ) : null}
    </div>
  );
}
