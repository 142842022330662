import React, { useEffect, useState } from "react";
import EditIcon from "../../assets/pencil.svg";
import RemoveIcon from "../../assets/remove.svg";
import DashboardIcon from "../../assets/dashboard.svg";
import { generatePath, Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { Company } from "../../api/CompanyApi";
import { User } from "../../api/UserApi";

export function CompanyList({
  nameFilter,
  users,
  companies,
  adminCompanyId,
  setEditingCompany,
  setDeletingCompany,
  setToast,
}: {
  nameFilter: string;
  users: User[];
  companies: Company[];
  adminCompanyId: string | null;
  setEditingCompany(company: Company | null): void;
  setDeletingCompany(company: Company | null): void;
  setToast(text: string): void;
}) {
  const [companyUserCount, setCompanyUserCount] = useState<{
    [companyId: string]: number;
  }>({});

  useEffect(() => {
    const newCount: { [companyId: string]: number } = {};
    users.map((user) => {
      if (user.companyId) {
        newCount[user.companyId] = newCount[user.companyId]
          ? newCount[user.companyId] + 1
          : 1;
      }
    });
    setCompanyUserCount(newCount);
  }, [users, companies]);

  return (
    <>
      <table className="admin-table align-self-center">
        <thead>
          <tr>
            <th>Name</th>
            <th>Domain</th>
            <th>Number of users</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies
            .filter((company) =>
              company.name.toLowerCase().includes(nameFilter)
            )
            .map((company) => (
              <tr key={company.id}>
                <td>{company.name}</td>
                <td>{company.domain}</td>
                <td>{companyUserCount[company.id] || "0"}</td>
                <td>
                  <div className="overview-admin-operations">
                    <Link
                      className="flex items-center"
                      to={generatePath(routes.dashboard, {
                        companyId: company.id,
                      })}
                    >
                      <img
                        className="pointer operation-img"
                        src={DashboardIcon}
                        alt="admin operation icon"
                      />
                    </Link>
                    <img
                      className="pointer operation-img"
                      src={EditIcon}
                      alt="admin operation icon"
                      onClick={() => setEditingCompany(company)}
                    />
                    {company.id !== adminCompanyId && (
                      <img
                        className="remove-icon pointer operation-img"
                        src={RemoveIcon}
                        alt="admin operation icon"
                        onClick={() => {
                          if (
                            !companyUserCount[company.id] ||
                            companyUserCount[company.id] == 0
                          ) {
                            setDeletingCompany(company);
                          } else {
                            setToast("There are still users in this company.");
                            setTimeout(() => setToast(""), 3000);
                          }
                        }}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
