import UserApi, { User } from "../api/UserApi";
import { useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { Outlet } from "react-router-dom";

export const UserProvider = () => {
  const [user, setUser] = useState<null | User>(null);

  const updateUser = () => {
    UserApi.getAccount()
      .then((currentUser) => {
        setUser(currentUser);
      })
      .catch(() => {
        setUser(null);
      });
  };

  useEffect(() => {
    updateUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      <Outlet />
    </UserContext.Provider>
  );
};
