import { HorizontalBarChart } from "../../components/horizontal-bar-chart/HorizontalBarChart";
import { Wordcloud } from "../../components/word-cloud/Wordcloud";
import { PieChart } from "../../components/pie-chart/PieChart";
import { DonutChart } from "../../components/donut-chart/DonutChart";
import { VerticalBarChart } from "../../components/vertical-bar-chart/VerticalBarChart";
import "./Dashboard.css";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { Tooltip } from "react-tooltip";
import QuestionMark from "../../assets/question-mark.svg";
import React, { Fragment, useEffect, useState } from "react";
import DatasetApi, {
  DatasetMetadata,
  MetadataStatistic,
} from "../../api/DatasetApi";
import { useParams, useSearchParams } from "react-router-dom";
import { SentimentTypes } from "../../constants/SentimentTypes";
import SearchIcon from "../../assets/search.svg";
import ReloadIcon from "../../assets/reload.svg";
import Dialog from "../../components/dialog/Dialog";
import { Timeline } from "../../components/timeline/Timeline";
import Select from "react-select";

export function Dashboard() {
  const MaintopicExplanation =
    "Distribution of main topics, with an overview of the sentiment for each topic.";
  const TermCloudExplanation =
    "Relevant terms extracted from the data set. The size of each term is correlated with the frequency.";
  const SentimentExplanation =
    "Distribution of the sentiment across the data set.";
  const EmotionExplanation =
    "Distribution of the emotions across the data set.";
  const TopicsExplanation =
    "Distribution of the topics across the data set (combined main & sub-topic).";
  const TimelineExplanation = "Evolution of the sentiment over time.";
  const StillAnalysingExplanation =
    "We are still analysing your data, please come back later!";

  const { companyId } = useParams();
  const [searchParams] = useSearchParams();

  const [dataset, setDataset] = useState<DatasetMetadata | null>(null);
  const [sentencesData, setSentencesData] = useState<
    MetadataStatistic[] | null
  >(null);
  const [topics, setTopics] = useState<{ name: string; values: number[] }[]>(
    []
  );
  const [allData, setAllData] = useState<MetadataStatistic[]>([]);
  const [allSetsToAnalyze, setAllSetsToAnalyze] = useState<
    MetadataStatistic[][]
  >([]);
  const [sentimentValues, setSentimentValues] = useState<number[][]>([]); // [[very_p, p, neu, n, very_n],[very_p, p, neu, n, very_n],...]
  const [sentimentLabels, setSentimentLabels] = useState<string[]>([]);
  const [subTopics, setSubTopics] = useState<{ name: string; value: number }[]>(
    []
  );
  const [subTopicValues, setSubTopicValues] = useState<number[]>([]);
  const [subTopicLabels, setSubTopicLabels] = useState<string[]>([]);
  const [emotions, setEmotions] = useState<string[]>([]);
  const [emotionValues, setEmotionValues] = useState<number[]>([]);
  const [shownEmotions, setShownEmotions] = useState<string[]>([]);
  const [shownEmotionValues, setShownEmotionValues] = useState<number[]>([]);
  const [generalSentimentValues, setGeneralSentimentValues] = useState<
    number[]
  >([]); //[very_p, p, neu, n, very_n]
  const [shownGeneralSentimentValues, setShownGeneralSentimentValues] =
    useState<number[]>([]);
  const [shownGeneralSentimentLabels, setShownGeneralSentimentLabels] =
    useState<string[]>([]);
  const [aspects, setAspects] = useState<string[]>([]);
  const [aspectValues, setAspectValues] = useState<number[]>([]);
  const [shownAspects, setShownAspects] = useState<string[]>([]);
  const [shownAspectValues, setShownAspectValues] = useState<number[]>([]);
  const [stillAnalysing, setStillAnalysing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [dataSetSelected, setDataSetSelected] = useState(false);

  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [selectedSentiments, setSelectedSentiments] = useState<string[]>([]);
  const [selectedEmotions, setSelectedEmotions] = useState<string[]>([]);
  const [selectedSubTopics, setSelectedSubTopics] = useState<string[]>([]);
  const [selectedAspects, setSelectedAspects] = useState<string[]>([]);

  useEffect(() => {
    if (searchParams.get("dataset")) {
      setDataSetSelected(true);
    } else {
      setDataSetSelected(false);
    }
  }, [searchParams]);

  async function fetchData(companyId: string) {
    let setsToAnalyze: MetadataStatistic[][] = [];

    const datasetParams = searchParams.get("dataset") as string;
    const mainDatasetId = datasetParams.replaceAll("-all", "");
    if (datasetParams.endsWith("-all")) {
      const allDatasets = await DatasetApi.getAllDatasets(companyId);

      setsToAnalyze = allDatasets
        .filter(
          (d) =>
            d.id === mainDatasetId || d.mainDatasetMetadataId === mainDatasetId
        )
        .filter((d) => d.analyzedURL)
        .map((d) => d.statistics);

      setDataset(allDatasets.find((d) => d.id === mainDatasetId) || null);
    } else {
      const foundDataset = await DatasetApi.getDataset(
        companyId,
        searchParams.get("dataset") as string
      );

      if (foundDataset.analyzedURL) {
        setsToAnalyze = [foundDataset.statistics];
      }

      setDataset(foundDataset);
    }

    if (setsToAnalyze.length === 0) {
      setStillAnalysing(true);
    } else {
      setStillAnalysing(false);
    }

    setAllSetsToAnalyze(setsToAnalyze);
    analyseAndSetStates(setsToAnalyze);
  }

  function analyseAndSetStates(sets: MetadataStatistic[][], reset?: boolean) {
    const topics: { name: string; values: number[] }[] = [];
    const subTopics: { name: string; value: number }[] = [];
    const emotions: string[] = [];
    const emotionValues: number[] = [];
    const generalSentimentValues: number[] = [0, 0, 0, 0, 0];
    const aspects: string[] = [];
    const aspectValues: number[] = [];

    if (sets.length === 0) {
      setStillAnalysing(true);
    } else {
      setStillAnalysing(false);
    }

    let setsToAnalyze = sets.map((s) =>
      s.filter(
        (setItem) =>
          (selectedTopics.includes(setItem.topic) ||
            selectedTopics.length === 0) &&
          (selectedEmotions.includes(setItem.emotion) ||
            selectedEmotions.length === 0) &&
          (selectedAspects.includes(setItem.aspect) ||
            selectedAspects.length === 0) &&
          (selectedSentiments.includes(setItem.sentiment) ||
            selectedSentiments.length === 0) &&
          (selectedSubTopics.includes(setItem.subTopic) ||
            selectedSubTopics.length === 0)
      )
    );

    if (reset === true) {
      setsToAnalyze = sets;
    }

    setAllData(setsToAnalyze.flat());

    for (const statistics of setsToAnalyze) {
      statistics.map((statistic) => {
        let index = emotions.findIndex((em) => em === statistic.emotion);
        if (index !== -1) {
          emotionValues[index] += 1;
        } else {
          emotions.push(statistic.emotion);
          emotionValues.push(1);
        }

        index = subTopics.findIndex(
          (sto) => sto.name === `${statistic.topic}-${statistic.subTopic}`
        );
        if (index !== -1) {
          subTopics[index].value += 1;
        } else {
          subTopics.push({
            name: `${statistic.topic}-${statistic.subTopic}`,
            value: 1,
          });
        }

        if (statistic.aspect !== "NULL") {
          index = aspects.findIndex((as) => as === statistic.aspect);
          if (index !== -1) {
            aspectValues[index] += 1;
          } else {
            aspects.push(statistic.aspect);
            aspectValues.push(1);
          }
        }

        index = topics.findIndex((to) => to.name === statistic.topic);
        if (index == -1) {
          topics.push({ name: statistic.topic, values: [0, 0, 0, 0, 0] });
          index = topics.findIndex((to) => to.name === statistic.topic);
        }
        switch (statistic.sentiment) {
          case SentimentTypes.NEUTRAL:
            topics[index].values[2] += 1;
            generalSentimentValues[2] += 1;
            break;
          case SentimentTypes.NEGATIVE:
            topics[index].values[1] -= 1;
            generalSentimentValues[1] -= 1;
            break;
          case SentimentTypes.VERY_NEGATIVE:
            topics[index].values[0] -= 1;
            generalSentimentValues[0] -= 1;
            break;
          case SentimentTypes.POSITIVE:
            topics[index].values[3] += 1;
            generalSentimentValues[3] += 1;
            break;
          case SentimentTypes.VERY_POSITIVE:
            topics[index].values[4] += 1;
            generalSentimentValues[4] += 1;
            break;
          default:
            break;
        }
      });
    }

    const sortedFilteredTopics = topics.sort((a, b) => {
      return (
        b.values.reduce((c, d) => c + d) - a.values.reduce((c, d) => c + d)
      );
    });

    const sortedSubTopics = subTopics.sort((a, b) => {
      return b.value - a.value;
    });

    setSentimentValues(sortedFilteredTopics.map((item) => item.values));
    setSentimentLabels(sortedFilteredTopics.map((item) => item.name));
    setTopics(sortedFilteredTopics);
    setGeneralSentimentValues(generalSentimentValues);
    setShownGeneralSentimentLabels(Object.values(SentimentTypes));
    setShownGeneralSentimentValues(generalSentimentValues);
    setEmotions(emotions);
    setEmotionValues(emotionValues);
    setShownEmotions(emotions);
    setShownEmotionValues(emotionValues);
    setSubTopics(sortedSubTopics);
    setSubTopicValues(sortedSubTopics.map((item) => item.value));
    setSubTopicLabels(sortedSubTopics.map((item) => item.name));
    setAspects(aspects);
    setAspectValues(aspectValues);
    setShownAspects(aspects);
    setShownAspectValues(aspectValues);
  }

  useEffect(() => {
    if (companyId && searchParams.get("dataset")) {
      setLoading(true);
      fetchData(companyId).finally(() => {
        setLoading(false);
      });
    }
  }, [companyId, searchParams]);

  return (
    <div className={"flex dashboard"}>
      <div className={"sidebar"}>
        <Sidebar />
      </div>
      <div className={`charts-wrapper flex-1`}>
        {!dataSetSelected ? (
          <div className={"overlay"}>
            Please select or upload a data set to view the results.
          </div>
        ) : loading ? (
          <div className="overlay">
            <div>Loading...</div>
          </div>
        ) : stillAnalysing ? (
          <div className="overlay">
            <div>{StillAnalysingExplanation}</div>
            <div
              className="mt-4 upload-button animated action-button pill w-fit"
              onClick={async () => {
                if (!companyId) {
                  return;
                }

                setLoading(true);
                await fetchData(companyId);
                setLoading(false);
              }}
            >
              Refresh
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="chart-filters-wrapper">
              <div className="chart-filters flex">
                <Select
                  options={topics.map((topic) => {
                    return { label: topic.name, value: topic.name };
                  })}
                  value={
                    selectedTopics.map((item) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                  isMulti
                  isSearchable
                  placeholder="Main Topic"
                  onChange={(values) => {
                    setSelectedTopics(values.map((v) => v.value.toString()));
                  }}
                />
                <Select
                  isMulti
                  isSearchable
                  options={aspects.map((aspect) => {
                    return {
                      label: aspect,
                      value: aspect,
                    };
                  })}
                  value={
                    selectedAspects.map((item) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                  placeholder="Aspect"
                  onChange={(values) => {
                    setSelectedAspects(values.map((v) => v.value.toString()));
                  }}
                />
                <Select
                  isMulti
                  isSearchable
                  options={Object.values(SentimentTypes).map((sentiment) => ({
                    label: sentiment,
                    value: sentiment,
                  }))}
                  value={
                    selectedSentiments.map((item) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                  placeholder="Sentiment"
                  onChange={(values) => {
                    setSelectedSentiments(
                      values.map((v) => v.value.toString())
                    );
                  }}
                />
                <Select
                  isMulti
                  isSearchable
                  options={emotions.map((emotion) => ({
                    label: emotion,
                    value: emotion,
                  }))}
                  value={
                    selectedEmotions.map((item) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                  placeholder="Emotion"
                  onChange={(values) => {
                    setSelectedEmotions(values.map((v) => v.value.toString()));
                  }}
                />
                <Select
                  isMulti
                  isSearchable
                  className="sub-topic-search select-search"
                  options={subTopics
                    .filter(
                      (subTopic) =>
                        selectedTopics.length === 0 ||
                        selectedTopics.includes(subTopic.name.split("-")[0])
                    )
                    .map((subTopic) => ({
                      label: subTopic.name,
                      value: subTopic.name,
                    }))}
                  value={
                    selectedSubTopics.map((item) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                  placeholder="Sub Topic"
                  onChange={(values) => {
                    setSelectedSubTopics(values.map((v) => v.value.toString()));
                  }}
                />
              </div>
              <div className="chart-filters-search-button">
                <img
                  className="search-icon"
                  src={SearchIcon}
                  alt="search"
                  onClick={() => {
                    analyseAndSetStates(allSetsToAnalyze);
                  }}
                />
              </div>
              <div className="chart-filters-reset-button">
                <div
                  className="reset-button"
                  onClick={() => {
                    setSelectedTopics([]);
                    setSelectedAspects([]);
                    setSelectedSentiments([]);
                    setSelectedEmotions([]);
                    setSelectedSubTopics([]);
                    analyseAndSetStates(allSetsToAnalyze, true);
                  }}
                >
                  <img className="reload-icon" src={ReloadIcon} alt="reload" />
                </div>
              </div>
            </div>
            <div className="dataset-title subtitle">
              {dataset?.datasetName ?? ""}
            </div>
            <div className="charts flex flex-wrap align-self-start">
              <div className={"chart half-width"}>
                <div className="flex">
                  <label className={"subtitle"}>Main Topics</label>
                  <div
                    data-tooltip-id="horizontalbarchart"
                    data-tooltip-content={MaintopicExplanation}
                    data-tooltip-place="top"
                    className={"flex flex-column"}
                  >
                    <img
                      src={QuestionMark}
                      className="tooltip-icon"
                      alt="tooltip"
                    />
                  </div>
                  <Tooltip className="tooltip" id="horizontalbarchart" />
                </div>
                {topics.length > 0 && sentimentValues.length > 0 && (
                  <HorizontalBarChart
                    labels={sentimentLabels}
                    datapoints={sentimentValues}
                    allData={allData}
                    setSentencesData={setSentencesData}
                  />
                )}
              </div>
              <div className={"chart half-width"}>
                <div className="flex">
                  <label className={"subtitle"}>Term cloud</label>
                  <div
                    data-tooltip-id="termcloudchart"
                    data-tooltip-content={TermCloudExplanation}
                    data-tooltip-place="top"
                    className={"flex flex-column items-center"}
                  >
                    <img
                      src={QuestionMark}
                      className="tooltip-icon"
                      alt="tooltip"
                    />
                  </div>
                  <Tooltip className="tooltip" id="termcloudchart" />
                </div>
                {shownAspects.length > 0 && shownAspectValues.length > 0 && (
                  <Wordcloud
                    setSentencesData={setSentencesData}
                    allData={allData}
                    labels={shownAspects}
                    values={shownAspectValues}
                  />
                )}
              </div>
              <div className={"chart half-width"}>
                <div className="flex">
                  <label className={"subtitle"}>Sentiment</label>
                  <div
                    data-tooltip-id="piechart"
                    data-tooltip-content={SentimentExplanation}
                    data-tooltip-place="top"
                    className={"flex flex-column items-center"}
                  >
                    <img
                      src={QuestionMark}
                      className="tooltip-icon"
                      alt="tooltip"
                    />
                  </div>
                  <Tooltip className="tooltip" id="piechart" />
                </div>
                {shownGeneralSentimentValues.length > 0 &&
                  shownGeneralSentimentLabels.length > 0 && (
                    <PieChart
                      labels={shownGeneralSentimentLabels}
                      datapoints={shownGeneralSentimentValues}
                      allData={allData}
                      setSentencesData={setSentencesData}
                    />
                  )}
              </div>
              <div className={"chart half-width"}>
                <div className="flex">
                  <label className={"subtitle"}>Emotion</label>
                  <div
                    data-tooltip-id="donutchart"
                    data-tooltip-content={EmotionExplanation}
                    data-tooltip-place="top"
                    className={"flex flex-column items-center"}
                  >
                    <img
                      src={QuestionMark}
                      className="tooltip-icon"
                      alt="tooltip"
                    />
                  </div>
                  <Tooltip className="tooltip" id="donutchart" />
                </div>
                {shownEmotions.length > 0 && shownEmotionValues.length > 0 && (
                  <DonutChart
                    labels={shownEmotions}
                    datapoints={shownEmotionValues}
                    allData={allData}
                    setSentencesData={setSentencesData}
                  />
                )}
              </div>
              <div className={"chart extended-width"}>
                <div className="flex">
                  <label className={"subtitle"}>Topics</label>
                  <div
                    data-tooltip-id="barchart"
                    data-tooltip-content={TopicsExplanation}
                    data-tooltip-place="top"
                    className={"flex flex-column items-center"}
                  >
                    <img
                      src={QuestionMark}
                      className="tooltip-icon"
                      alt="tooltip"
                    />
                  </div>
                  <Tooltip className="tooltip" id="barchart" />
                </div>
                {subTopics.length > 0 && subTopicValues.length > 0 && (
                  <VerticalBarChart
                    labels={subTopicLabels}
                    input={subTopicValues}
                    allData={allData}
                    setSentencesData={setSentencesData}
                  />
                )}
              </div>
            </div>
            <div className={"chart full-width"}>
              <div className="flex">
                <label className={"subtitle"}>Evolution over time</label>
                <div
                  data-tooltip-id="linechart"
                  data-tooltip-content={TimelineExplanation}
                  data-tooltip-place="top"
                  className={"flex flex-column items-center"}
                >
                  <img src={QuestionMark} className="tooltip-icon"></img>
                </div>
                <Tooltip className="tooltip" id="linechart"></Tooltip>
              </div>
              {new Set(allData.map((d) => d.date)).size <= 1 ? (
                <div className="placeholder">
                  The time graph can't be shown yet as there is only data of 1
                  day available.
                </div>
              ) : (
                <Timeline statistics={allData} />
              )}
            </div>
          </Fragment>
        )}
      </div>
      <Dialog
        isOpen={sentencesData !== null}
        onRequestClose={() => {
          setSentencesData(null);
        }}
        title={"Sentences"}
        size={"side"}
        shouldCloseOnOverlayClick={false}
      >
        <div className="sentences">
          {sentencesData?.map((item) => {
            return <div className="sentence">{item.sentence}</div>;
          })}
        </div>
      </Dialog>
    </div>
  );
}
