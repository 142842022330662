import React, { ReactElement } from "react";

export function FormWindow({
  form,
  onSubmit,
}: {
  form: ReactElement;
  onSubmit: () => void;
}) {
  return (
    <div className="form-window flex">
      <form
        className="form-window-form flex flex-column"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {form}
      </form>
    </div>
  );
}
