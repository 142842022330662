export function DeleteForm(props: {
  object: { id: string | undefined; name: string | undefined };
  onDelete: (result: boolean, id: string | undefined) => void;
}) {
  const { object, onDelete } = props;

  if (!object.id || !object.name) {
    onDelete(false, undefined);
    return null;
  }

  const handleDelete = async () => {
    onDelete(true, object.id);
  };

  return (
    <div className="marker-info flex flex-column">
      Are you sure you want to delete {object.name}?
      <button className="button-red small mt-4 w-fit" onClick={handleDelete}>
        Delete
      </button>
    </div>
  );
}
