import React, { useEffect, useState } from "react";
import UserApi, { User } from "../../api/UserApi";
import "./AdminPage.css";
import "../../index.css";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import AccountIcon from "../../assets/account.svg";
import CompanyIcon from "../../assets/company.svg";
import EditIcon from "../../assets/pencil.svg";
import RemoveIcon from "../../assets/remove.svg";
import Dialog from "../../components/dialog/Dialog";
import { UserForm } from "../../components/user-form/UserForm";
import { Input } from "../../components/input/Input";
import authApi, { UserType } from "../../api/AuthApi";
import Logout from "../../assets/logout.svg";
import UploadButton from "../../assets/upload-button.svg";
import SearchIcon from "../../assets/search.svg";
import { CompanyList } from "../../components/company-list/CompanyList";
import { DeleteForm } from "../../components/delete-form/DeleteForm";
import CompanyApi, { Company } from "../../api/CompanyApi";
import { CompanyForm } from "../../components/company-form/CompanyForm";

enum ContentType {
  USER = "USER",
  COMPANY = "COMPANY",
}

export function AdminPage() {
  const [users, setUsers] = useState<User[]>([]);
  const [nameFilter, setNameFilter] = useState<string>("");
  const [creatingUser, setCreatingUser] = useState<boolean>(false);
  const [creatingCompany, setCreatingCompany] = useState<boolean>(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [editingCompany, setEditingCompany] = useState<Company | null>(null);
  const [deletingUser, setDeletingUser] = useState<User | null>(null);
  const [deletingCompany, setDeletingCompany] = useState<Company | null>(null);
  const [content, setContent] = useState<ContentType>(ContentType.USER);
  const [toast, setToast] = useState<string>("");
  const [companies, setCompanies] = useState<Company[]>([]);
  const [adminCompanyId, setAdminCompanyId] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    UserApi.getUsers().then(setUsers);
    CompanyApi.getCompanies().then(setCompanies);
  }, []);

  useEffect(() => {
    const admin = users.filter((user) => user.userType === UserType.ADMIN);
    if (admin[0]) {
      setAdminCompanyId(admin[0].companyId);
    }
  }, [users]);

  function handleLogout() {
    authApi.logout();
    navigate(routes.login);
  }

  return (
    <div className={"height flex flex-column justify-space-between"}>
      <div className={"container flex flex-column"}>
        <div className={"admin-filters flex justify-space-between"}>
          <div className={"title"}>Overview</div>
          <div className={"list-chooser flex"}>
            <div
              className={`flex ${
                content === ContentType.USER
                  ? "selected-type type-icon-wrapper"
                  : "type-icon-wrapper pointer"
              }`}
              onClick={() => {
                setContent(ContentType.USER);
                setNameFilter("");
              }}
            >
              <img src={AccountIcon} alt={""} className={"type-icon"} />
            </div>
            <div
              className={`flex ${
                content === ContentType.COMPANY
                  ? "selected-type type-icon-wrapper"
                  : "type-icon-wrapper pointer"
              }`}
              onClick={() => {
                setContent(ContentType.COMPANY);
                setNameFilter("");
              }}
            >
              <img src={CompanyIcon} alt={""} className={"type-icon"} />
            </div>
          </div>
          <div className={"admin-name-input"}>
            <Input
              type={"text"}
              onChange={(result) => setNameFilter(result.toLowerCase())}
              placeholder={"Search by name"}
              className={"admin-name-input"}
              required={false}
              value={nameFilter}
              image={SearchIcon}
            />
          </div>
        </div>
        {content === "USER" && (
          <table className="admin-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Company</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users
                .filter(
                  (user) =>
                    user.name.toLowerCase().includes(nameFilter) ||
                    user.email.toLowerCase().includes(nameFilter)
                )
                .map((user) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.status}</td>
                    <td>
                      {
                        companies.find(
                          (company) => company.id === user.companyId
                        )?.name
                      }
                    </td>
                    <td>
                      <div className="overview-admin-operations">
                        {user.companyId ? (
                          <Link
                            to={generatePath(routes.dashboard, {
                              companyId: user.companyId,
                            })}
                          >
                            <img
                              className="pointer operation-img"
                              src={AccountIcon}
                              alt="admin operation icon"
                            />
                          </Link>
                        ) : null}
                        <img
                          className="edit-icon pointer operation-img"
                          src={EditIcon}
                          alt="admin operation icon"
                          onClick={() => setEditingUser(user)}
                        />
                        {user.userType !== UserType.ADMIN && (
                          <img
                            className="remove-icon pointer operation-img"
                            src={RemoveIcon}
                            alt="admin operation icon"
                            onClick={() => setDeletingUser(user)}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {content === "COMPANY" && (
          <CompanyList
            users={users}
            nameFilter={nameFilter}
            companies={companies}
            adminCompanyId={adminCompanyId}
            setEditingCompany={setEditingCompany}
            setDeletingCompany={setDeletingCompany}
            setToast={setToast}
          />
        )}
        <Dialog
          isOpen={deletingCompany !== null}
          onRequestClose={() => setDeletingCompany(null)}
          title={"Delete company"}
          size={"xsmall"}
        >
          <DeleteForm
            object={{ id: deletingCompany?.id, name: deletingCompany?.name }}
            onDelete={(result, id) => {
              if (result && id) {
                if (!deletingCompany) {
                  return;
                }
                if (result && id) {
                  setCompanies(
                    companies.filter((company) => company.id !== id)
                  );
                  CompanyApi.deleteCompany(id)
                    .then(() => {
                      setCompanies(
                        companies.filter(
                          (company) => company.id !== deletingCompany.id
                        )
                      );
                      setDeletingCompany(null);
                    })
                    .catch(() => {
                      setDeletingCompany(null);
                      setToast(
                        "Something went wrong while trying to delete the company"
                      );
                      setTimeout(() => setToast(""), 3000);
                    });
                }
              }
              setDeletingCompany(null);
            }}
          />
        </Dialog>
        <Dialog
          isOpen={deletingUser !== null}
          onRequestClose={() => setDeletingUser(null)}
          title={"Delete user"}
          size={"xsmall"}
        >
          <DeleteForm
            object={{ id: deletingUser?.id, name: deletingUser?.name }}
            onDelete={(result: boolean, id: string | undefined) => {
              if (!deletingUser) {
                return;
              }

              if (result && id) {
                UserApi.deleteUser(id)
                  .then(() => {
                    setUsers(
                      users.filter((user) => user.id !== deletingUser.id)
                    );
                    setDeletingUser(null);
                  })
                  .catch(() => {
                    setDeletingUser(null);
                    setToast(
                      "Something went wrong while trying to delete the user"
                    );
                    setTimeout(() => setToast(""), 3000);
                  });
              }
            }}
          />
        </Dialog>
        <Dialog
          isOpen={editingUser !== null}
          onRequestClose={() => setEditingUser(null)}
          title={"Edit user"}
          size={"xsmall"}
        >
          <UserForm
            user={editingUser}
            companies={companies}
            adminCompanyId={adminCompanyId}
            onClose={(result, updatedUser) => {
              if (result && updatedUser) {
                setUsers(
                  users.map((user) => {
                    if (user.id !== updatedUser.id) {
                      return user;
                    }

                    return updatedUser;
                  })
                );
              }
              setEditingUser(null);
            }}
          />
        </Dialog>
        <Dialog
          isOpen={creatingUser}
          onRequestClose={() => {
            setCreatingUser(false);
          }}
          title={"Create user"}
          size={"xsmall"}
        >
          <UserForm
            user={null}
            companies={companies}
            adminCompanyId={adminCompanyId}
            onClose={(result, user) => {
              if (result && user) {
                setUsers([user, ...users]);
              }
              setCreatingUser(false);
            }}
          />
        </Dialog>
        <Dialog
          isOpen={creatingCompany}
          onRequestClose={() => setCreatingCompany(false)}
          title={"Create company"}
          size={"xsmall"}
        >
          <CompanyForm
            company={null}
            onClose={(result, company) => {
              if (result && company) {
                setCompanies([company, ...companies]);
              }
              setCreatingCompany(false);
            }}
          />
        </Dialog>
        <Dialog
          isOpen={editingCompany !== null}
          onRequestClose={() => setEditingCompany(null)}
          title={"Edit company"}
          size={"xsmall"}
        >
          <CompanyForm
            company={editingCompany}
            onClose={(result, updatedCompany) => {
              if (result && updatedCompany) {
                setCompanies([
                  updatedCompany,
                  ...companies.filter(
                    (company) => company.id !== updatedCompany.id
                  ),
                ]);
              }
              setEditingCompany(null);
            }}
          />
        </Dialog>
      </div>

      <div
        className={
          "flex justify-space-between flex-items-end admin-buttons-wrapper"
        }
      >
        <div
          onClick={() => handleLogout()}
          className={"logout-button action-button animated"}
        >
          <img className="logout-icon" src={Logout} alt={"logout button"} />
        </div>
        {toast.length > 0 && (
          <div className={"flex justify-center error-text mb-6"}>
            <div>{toast}</div>
          </div>
        )}
        <div
          onClick={() => {
            if (content === ContentType.USER) {
              setCreatingUser(true);
            } else if (content === ContentType.COMPANY) {
              setCreatingCompany(true);
            }
          }}
          className={"upload-button animated action-button"}
        >
          <img
            className="upload-icon"
            src={UploadButton}
            alt={"create user button"}
          />
        </div>
      </div>
    </div>
  );
}
